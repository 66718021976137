import React, {FunctionComponent, useEffect, useState} from 'react'
import {useForm, useFormState, SubmitHandler, FormProvider} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'
import {confirmAlert} from 'react-confirm-alert'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import dayjs from 'dayjs'

import ConfirmationModal from '../../Modal/ConfirmationModal'
import Footer from '../../Footer/Footer'

import ActionsForm from '../Blocks/Distribution/ActionsForm'
import SessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import {toastError, toastSuccess} from '../../../util/Toast'

import {usePrompt} from '../../../util/Navigation'

import {TFieldErrors, TProps} from './types'
import Setting from './__partials/Setting'
import ProductList from './__partials/ProductList'
import {ISettingForm, TSessionProducts, ProductListInterface} from '../../../../../domain/Distribution'
import ProductGateway from '../../../../../gateway/Distribution/ProductGateway'
import {ListRequest} from '../../../../../useCase/Distribution/Product/ListRequest'
import ListUseCase from '../../../../../useCase/Distribution/Product/ListUseCase'
import {AmountDistributedInterface} from '../../../../../domain/Distribution/AmountDistributed'
import Spinner from '../../Spinner/Spinner'
import {isApiError} from '../../../../type/ApiError'
import EditionAndDownload, { TEditionDownloadItem } from '../Blocks/EditionAndDownload'
import { useAppSelector } from '../../../../store/hook'
import DistributionSessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import FormFieldErrors from '../../Alert/FormFieldErrors/FormFieldErrors'

const transformSessionProducts = (amounts: AmountDistributedInterface[]) => {
  return amounts.reduce((acc, product) => {
    acc[product.product.id] = {
      productId: product.product.id,
      financialAmount: product.financialAmount,
      propertyAmount: product.propertyAmount,
    }
    return acc
  }, {} as Record<string, TSessionProducts>)
}

const Form: FunctionComponent<TProps> = ({session, isLectureMode, formErrors, setFormErrors}) => {
  const {t} = useTranslation()
  const {sessionType, uuid} = useParams()
  const navigate = useNavigate()

  const [fieldErrors, setFieldErrors] = useState<TFieldErrors>({})
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const [productList, setProductList] = useState<ProductListInterface[] | null>(null)
  const [selectedYear, setSelectedYear] = useState<number|null>(session?.year ?? null)
  const openDatalistFilterDistributionSession = useAppSelector((state) => state.event.openDatalistFilterDistributionSession)
  const editionArray: TEditionDownloadItem[] = [
    {
      type: 'transfer',
      title: 'distribution.form.setting.actions-editions.transfer',
    },
    {
      type: 'syntheses',
      title: 'distribution.form.setting.actions-editions.syntheses',
    }
  ]

  const methods = useForm<ISettingForm>({
    defaultValues: {
      actions: {
        process: '',
        cancel: '',
        control: '',
        invalidate: '',
        validate: '',
        verify: ''
      },
      sessionCriteria: {
        label: session?.label || '',
        month: session?.month || 0,
        year: session ? new Date(session?.year, 0, 1) : null,
      },
      sessionProducts: transformSessionProducts(session?.amounts || [])
    }
  })

  const {isDirty} = useFormState({control: methods.control})

  const watchYear = methods.watch('sessionCriteria.year')
  const watchMonth = methods.watch('sessionCriteria.month')

  const checkParameters = (): boolean => {
    const errors: TFieldErrors = {}
    let success = true

    if (!watchYear) {
      errors['year'] = t('common.required.date.year')
      success = false
    }
    if (!watchMonth) {
      errors['month'] = t('common.required.date.month')
      success = false
    }

    const sessionProducts = methods.getValues('sessionProducts') || [];
    const hasValidProduct = Object.values(sessionProducts).some(
      (product: TSessionProducts) => !!product.productId
    )

    if (!hasValidProduct) {
      errors['sessionProducts'] = t('common.required.distribution-without-product')
      success = false
    }

    if (!success) {
      setFieldErrors(errors)
      return false
    }

    setFieldErrors({})
    return true
  }

  const handleConfirm = (data: ISettingForm) => {
    const type = sessionType ?? ''
    const gateway = new SessionGateway()

    data.sessionProducts = Object.fromEntries(
      Object.entries(data.sessionProducts).filter(
        ([_, product]: [string, Partial<TSessionProducts>]) => !!product.productId
      )
    )

    const handleSuccess = async (typeOfSuccess: string, uuid: string) => {
      if (typeOfSuccess === 'create') {
        methods.reset(data)
        toastSuccess(t('distribution.session.notify.add-success'))

        setTimeout(() => {
          navigate(`/${t('url.distribution.session.edit-setting')}/${sessionType}/${uuid}`);
        }, 50);

        return;
      }

      toastSuccess(t('distribution.session.notify.update-success'))
      setUuidRefresh(uuidV4())
    }

    const handleError = (e: unknown) => {
      if (isApiError(e) && e.code !== 500 && e.data?.errors[0]?.messages?.length) {
        toastError(e.data?.errors[0]?.messages[0])
      } else {
        toastError(t('distribution.session.notify.update-error'))
      }
    }

    if (uuid) {
      gateway.update(data, type, uuid).then(() => handleSuccess('update', uuid)).catch(handleError)
    } else {
      gateway.create(data, type).then((res) => handleSuccess('create', res.id)).catch(handleError)
    }
  }

  const handleSubmitForm: SubmitHandler<ISettingForm> = (data) => {
    if (!checkParameters()) {
      return
    }

    confirmAlert({
      customUI: ({onClose}) => (
        <ConfirmationModal onConfirm={() => handleConfirm(data)} onClose={onClose}/>
      ),
    })
  }

  usePrompt(!isLectureMode && isDirty, methods.handleSubmit(handleConfirm))

  useEffect(() => {
    if (!watchYear) {
      if (null !== selectedYear) {
        setSelectedYear(null)
      }
    } else {
      setSelectedYear(parseInt(dayjs(watchYear).format('YYYY')))
    }
  }, [watchYear])

  useEffect(() => {
    if (!sessionType || !watchYear || !watchMonth) {
      setProductList(null)
      return
    }

    setProductList(null)

    const gateway = new ProductGateway()
    const listRequest: ListRequest = {
      month: watchMonth,
      year: parseInt(dayjs(watchYear).format('YYYY')),
      type: sessionType,
    }
    new ListUseCase(gateway)
      .execute(listRequest)
      .then(products => {
        if (products && products.data && products.data?.length > 0) {
          setProductList(products.data)
          return
        }

        setProductList(null)
      })
  }, [selectedYear, watchMonth, sessionType])

  const handleClickDownload = (item: TEditionDownloadItem) => {
    const sessionId = uuid
    const type = item.type
    if(sessionId) {
      new DistributionSessionGateway().getExtractAsBlob(sessionId, type, openDatalistFilterDistributionSession.filters).then(response => {
        if (response) {
          if (response) {
            downloadBlobFile(response, t(`distribution.extract.${type}`))
          }
        }
      }).catch(response => {
        if (!response.message?.includes(' status code 500') && response.blob) {
          toastError(response.blob)
        }
        else
          toastError(t('distribution.notify.export-control-error'))
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)} className="flex-container">

        <Setting
          session={session}
          isLectureMode={isLectureMode}
          fieldErrors={fieldErrors}
          sessionType={sessionType ?? ''}
        />

        {uuid && (
          <ActionsForm
            sessionId={uuid}
            uuidRefresh={uuidRefresh}
            setFormErrors={setFormErrors}
          />
        )}
        <div className="col-md-12">
          <FormFieldErrors errors={formErrors?.errors ?? null} title={formErrors?.title ?? t('distribution.form.product.form-error-base-message')}/>
        </div>

        {productList && watchMonth && watchYear && (
          <ProductList
            productList={productList}
            session={session}
            isLectureMode={isLectureMode}
            fieldErrors={fieldErrors}
          />
        )}

        {(productList == null || productList.length === 0) && watchMonth !== null && watchYear !== null && (
          <section className="container--spinner"><Spinner size={150}/></section>
        )}

      {uuid &&  <EditionAndDownload title={t('distribution.form.setting.actions-editions.title-edition')}
                          register={methods.register}
                          control={methods.control}
                          editionArray={editionArray}
                          sessionId={uuid}
                          parentPath={"editions."}
                          classes={"col-md-12"}
                          onClick={handleClickDownload}
      />}

        <Footer isLectureMode={isLectureMode} disabled={isLectureMode}
                onCancelUrl={t('url.distribution.dashboard')}/>
      </form>
    </FormProvider>
  )
}

export default Form
