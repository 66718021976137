import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {PersonInterface} from '../../../../../../../domain/Customer/Person'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import AlertInfo from '../../../../Alert/AlertInfo/AlertInfo'
import RadioYesOrNot from '../../../../Elements/RadioYesOrNot'
import InputDate from '../../../../Elements/InputDate'
import {v4 as uuidV4} from 'uuid'
import {useFormContext} from 'react-hook-form'
import Input from '../../../../Elements/Input'
import CommunicationTableTypeBlock from './CommunicationTableTypeBlock'

type Props = {
  customer: CustomerInterface
  referential: ReferentielInterface
  isLectureMode: boolean | undefined
}

const CommunicationBlock: FunctionComponent<Props> = ({customer, referential, isLectureMode}) => {
  const { t } = useTranslation()
  const {register, control} = useFormContext()

  return (
    <div className={`form-bloc form--bloc--communication`}>
      <div className="form-bloc__title">{t('account.form.communication.title')}</div>
      {customer && customer.persons && customer.persons?.length > 1 && (
      <div className="flex-container">
        <div className="col-md-12">
          <AlertInfo text={t('account.form.communication.alert-sign-elec')}/>
        </div>
      </div>
      )}
      <div className="form-bloc__form flex-container">
        {customer.persons?.map((person: PersonInterface, index: number) => {
          return person.id && <div className="col-md-6">
            <div className="flex-container">
              <div className="col-md-12">{t('account.form.person')} {index + 1}</div>
              <Input id={`persons.${index}.email`}
                     name={`persons.${index}.email`}
                     label={t('account.form.communication.personEmail')}
                     type={`email`}
                     validator={`email`}
                     register={register}
                     classes={`col-md-12`}
              />
              <Input id={`persons.${index}.phone`}
                     name={`persons.${index}.phone`}
                     label={t('account.form.communication.personPhone')}
                     type={'tel'}
                     validator={'tel'}
                     register={register}
                     classes={`col-md-12`}
              />
            </div>
          </div>
        })}
      </div>
      {customer.persons?.map((person: PersonInterface, index: number) => {
        return index === 0 && person.id && <div className="form-bloc__form flex-container">
          <div className="col-md-12">{t('account.form.communication.communication-person')}</div>
          <div className="col-md-4">
            <RadioYesOrNot id={'customerExtranetRadio'} name={'customerExtranetRadio'} register={register}
                           label={t('account.form.communication.personExtranet')}/>
          </div>
          <div className="col-md-4">
            <div className="form-control">
              <label htmlFor={`persons.${index}.extranetCreatedAt`}
                     className="form-control__label">{t('account.form.communication.personExtranetCreatedAt')}</label>
              <div className="form-control__input">
                <InputDate id={`persons.${index}.extranetCreatedAt`}
                           name={`persons.${index}.extranetCreatedAt`}
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <CommunicationTableTypeBlock referential={referential} isLectureMode={isLectureMode} indexPerson={index} />
          </div>
        </div>
      })}
    </div>
  );
}

export default CommunicationBlock
