import { Presenter } from '../Presenter';
import { TransactionListInterface } from '../../domain/Movement/TransactionList';

class TransactionListPresenter extends Presenter {

  private useCase: {
    getTransactions: { data: TransactionListInterface[] | null, nb_element: number }
  }

  constructor(getTransactions: any) {
    super({
      viewModel: {
        title: 'movement.search-a-movement',
        heading: [
          'movement.heading.action',
          'movement.investment.heading.date',
          'movement.investment.heading.action_confirmed_at',
          'movement.investment.heading.esn_type',
          'movement.investment.heading.product',
          'movement.investment.heading.share_count',
          'movement.investment.heading.share_start',
          'movement.investment.heading.share_end',
          'movement.investment.heading.possession_date',
          'movement.investment.heading.share_price',
          'movement.investment.heading.amount',
          'movement.investment.heading.property_type',
          'movement.investment.heading.partner_code',
          'movement.investment.heading.partner_name',
          'movement.investment.heading.movement_type',
          'movement.investment.heading.dismembermentEndAt',
          'movement.investment.heading.dismembermentKey',
          'movement.investment.heading.status',
          'movement.investment.heading.source',
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'name', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });
    this.useCase = {
      getTransactions,
    }
  }

  async load() {
    try {
      this._setTransactions(await this.useCase.getTransactions)
    } catch (e) {
      // Do nothing
    }
  }

  _setTransactions(transactions: { data: TransactionListInterface[]|null, nb_element: number }) {
    this.update({
      data: transactions.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': transactions.nb_element,
      }
    })
  }
}

export default TransactionListPresenter;
