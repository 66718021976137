import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import { useFormContext } from 'react-hook-form'
import SelectCustom from '../../../../Elements/Select'

type Props = {
  referential: ReferentielInterface
}

const DistributionBlock: FunctionComponent<Props> = ({referential}) => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <div className={`form-bloc form--bloc--distribution`}>
      <div className="form-bloc__title">{t('account.form.distribution.title')}</div>
      <div className="form-bloc__form">
        <div className="flex-container">
          <SelectCustom classes="col-md-6" label={t('account.form.distribution.paymentMethod')}
                        id={`persons.0.fiscality.paymentMethod`}
                        name={`persons.0.fiscality.paymentMethod`}
                        options={referential.prospect.payment_method || []}
                        register={register}
            />
          <SelectCustom classes="col-md-6" label={t('account.form.distribution.paymentPeriodicity')}
                        id={`distributionPaymentPeriodicity`}
                        name={`distributionPaymentPeriodicity`}
                        options={referential.prospect.payment_periodicity || []}
                        register={register}
            />
        </div>
      </div>
    </div>
  )
}

export default DistributionBlock
