import React, {FunctionComponent, InputHTMLAttributes} from 'react'

import {IFormFieldProps} from './IFormFieldProps'
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./InputDate.scss";
import fr from "date-fns/locale/fr";
import {
    dateToString,
    stringToDate
} from '../../../transformer/StringDateToDateObject/StringDateToDateObject'
import {Control, Controller, FieldErrors, useWatch} from "react-hook-form";
import InputMask from "react-input-mask";
import {TargetPropertyInObjWithStringPath} from "../../util/TargetPropertyInObjWithStringPath";
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

registerLocale("fr", fr);

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    id: string
    name: string
    classes?: string
    containerClasses?: string
    labelClasses?: string
    defaultValue?: string
    isLectureMode?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    control: Control<any, any>
    errorMessage?: string
    errors?: FieldErrors
    dynamicError?: string
    onChangeCb?: (value: string) => void
}

const InputDate: FunctionComponent<IProps> = ({
                                                  id,
                                                  name,
                                                  classes,
                                                  containerClasses,
                                                  labelClasses,
                                                  label,
                                                  defaultValue,
                                                  isLectureMode,
                                                  disabled,
                                                  readOnly,
                                                  required,
                                                  control,
                                                  errorMessage,
                                                  onChangeCb,
                                                  dynamicError,
                                                  ...rest
                                              }) => {


  const val = useWatch({
    defaultValue: defaultValue,
    control: control,
    name: name
  })

  const formInput =
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({field}) => {
        return (
          <DatePicker
            selected={stringToDate(val)}
            onChange={
              (date: Date | null) => {
                field.onChange(dateToString(date));
                onChangeCb && onChangeCb(dateToString(date))
              }
            }
            name={name}
            className={isLectureMode ? 'form-control__readonly' : ''}
            disabled={disabled}
            readOnly={readOnly}
            showPopperArrow={false}
            isClearable={!disabled && !readOnly}
            placeholderText="__/__/____"
            locale="fr"
            dateFormat="dd/MM/yyyy"
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(3000, 12, 31)}
            customInput={
              <InputMask
                name={name}
                id={id}
                value={val}
                mask="99/99/9999"
                maskPlaceholder="__/__/____"
                alwaysShowMask={true}
                required={required}
              />
            }
          />
        );
      }}
    />

  return (
    <div className={`${classes}`}>
      {label ?
        <div className="form-control relative">
          <label htmlFor={id} className={`form-control__label ${labelClasses || ""}`}>{label}</label>
          <div className={`form-control__input ${containerClasses || ""}`}>
            { formInput }
            {rest?.errors && TargetPropertyInObjWithStringPath(name, rest?.errors) &&
              <p className="input-error-msg">{errorMessage}</p>}
            {!rest?.errors && dynamicError &&
              <FieldErrorMessage message={dynamicError}/>}
          </div>
        </div>
      :
        <div className={`form-control__input ${containerClasses || ""}`}>
          {formInput}
          {rest?.errors && TargetPropertyInObjWithStringPath(name, rest?.errors) &&
            <p className="input-error-msg">{errorMessage}</p>}
          {!rest?.errors && dynamicError &&
            <p className="input-error-msg">{dynamicError}</p>}
        </div>
      }
    </div>
  )
}

export default InputDate
