import { FilterDismembermentLedgerInterface } from '../../../domain/DismembermentLedger/DismembermentLedgerList'
import { DismembermentLedgerType } from '../../../domain/DismembermentLedger/DismembermentLedgerGatewayInterface'

interface ListRequestInterface {
  type: DismembermentLedgerType
  currentPage: number
  itemPerPage: number
  filter: FilterDismembermentLedgerInterface|null
}

class ListRequest implements ListRequestInterface{

  constructor(
    public type: DismembermentLedgerType,
    public currentPage: number,
    public itemPerPage: number,
    public filter: FilterDismembermentLedgerInterface|null,
  ) {
    this.type = type
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
