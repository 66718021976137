import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface';
import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import blockScrollBody from '../../../util/BlockScroll';
import { setOpenAccountUndividedForm, setOpenPartnerAddAddressEvent } from '../../../../store/component/event';
import getClassForOverlay from '../../../util/Sidebar';
import { Undivided } from '../../../../../domain/Prospect/Undivided';
import { Person } from '../../../../../domain/Prospect/Person';
import { addUndivided, updateUndivided } from '../../../../store/component/prospect';
import { ProspectInterface } from '../../../../../domain/Prospect/Prospect';
import CityDatalist from '../../Datalist/City/CityDatalist';
import CountryDatalist from '../../Datalist/Country/CountryDatalist';
import { City } from '../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import InputView from '../../Elements/InputView';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { toastSuccess } from '../../../util/Toast';
import { usePrompt } from '../../../util/Navigation';
import SelectCustom from '../../Elements/Select';
import InputDate from '../../Elements/InputDate';
import CityAndPostcode from '../../Datalist/CityAndPostcode/CityAndPostcode';
import {Address} from "../../../../../domain/Address/Address";
import CheckboxToggle from '../../Elements/CheckboxToggle'
import Input from '../../Elements/Input'

interface IFormInput {
  id: string
  gerant: boolean
  ppe: boolean
  ppeCategory: string|null
  shareholding: string
  personId: string
  title: string|null
  status: string|null
  lastName: string|null
  maidenName: string|null
  firstName: string|null
  birthdate: string|null
  birthCountry: Country|null
  birthCityForeign: string|null
  birthCity: City|null
  nationality: Country|null
  addressId: string
  addressFirstLine: string|null
  addressSecondLine: string|null
  addressThirdLine: string|null
  zipcode: string|null
  city: City|null
  country: Country|null
}

type Props = {
  referential: ReferentielInterface
  prospect?: ProspectInterface
}

const STATUS_ACTIVE = 'active';

const AddUndivided: FunctionComponent<Props> = ({prospect, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const methods = useForm();

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const openForm = useAppSelector((state) => state.event.openAccountUndividedForm)
  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const watchCivility = watch('title');
  const watchPpe = watch('ppe');

  useEffect(() => {
    blockScrollBody(openForm.show)

    if (openForm.undivided) {
      setValue('id', openForm.undivided?.id || '')
      setValue('gerant', openForm.undivided?.person?.gerant || false)
      setValue('ppe', openForm.undivided.ppe)
      setValue('ppeCategory', openForm.undivided?.person?.ppeCategory || null)
      setValue('shareholding', openForm.undivided.shareHolding)
      setValue('personId', openForm.undivided?.person?.id || '')
      setValue('title', openForm.undivided?.person?.title || null)
      setValue('status', openForm.undivided.status)
      setValue('lastName', openForm.undivided?.person?.lastname || null)
      setValue('maidenName', openForm.undivided?.person?.maidenName || null)
      setValue('firstName', openForm.undivided?.person?.firstname || null)
      setValue('birthdate', openForm.undivided?.person?.birthdate || null)
      // setValue('birthCountry', openForm.undivided?.person?.birthCountry || null)
      setValue('birthCity', openForm.undivided?.person?.birthCity || null)
      // setValue('nationality', openForm.undivided?.person?.nationality || null)
      setValue('addressId', openForm.undivided?.person?.address?.id || '')
      setValue('addressFirstLine', openForm.undivided?.person?.address?.address || null)
      setValue('addressSecondLine', openForm.undivided?.person?.address?.address2 || null)
      setValue('addressThirdLine', openForm.undivided?.person?.address?.address3 || null)
      setValue('zipcode', openForm.undivided?.person?.address?.postcode || null)

      if (
        openForm.undivided.person &&
        openForm.undivided.person.address &&
        openForm.undivided.person.address.city
      ) {
        setValue('city', openForm.undivided.person.address.city || null)
      }

      if (
        openForm.undivided.person &&
        openForm.undivided.person.address &&
        openForm.undivided.person.address.country
      ) {
        setValue('country', openForm.undivided.person.address.country || null)
      }

    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('title', null)
      setValue('status', STATUS_ACTIVE)
      setValue('lastName', null)
      setValue('maidenName', null)
      setValue('firstName', null)
      setValue('birthdate', null)
      // setValue('birthCountry', null)
      setValue('birthCity', null)
      // setValue('nationality', null)
      setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('addressFirstLine', null)
      setValue('addressSecondLine', null)
      setValue('addressThirdLine', null)
      setValue('zipcode', null)
      setValue('city', null)
      setValue('country', null)
      setValue('ppe', false)
      setValue('gerant', false)
      setValue('shareholding', '')
    }
  }, [openForm])

  useEffect(() => {
    if (countryByDefault && openForm) {
      setValue('birthCountry', openForm?.undivided?.person?.birthCountry || countryByDefault[0]);
      setValue('nationality',  openForm?.undivided?.person?.nationality || countryByDefault[0]);
      setValue('country',  openForm?.undivided?.person?.address?.country || countryByDefault[0]);
    }
  }, [countryByDefault, openForm])

  useEffect(() => {
    if (!watchPpe) {
      setValue('ppeCategory', '')
    }
  }, [watchPpe])

  function handleClose() {
    dispatch(setOpenAccountUndividedForm({show: false, undivided: null}))
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const personType = prospect && prospect.moralType === 'business' ? 'beneficiary' : 'joint';

    const undivided = new Undivided(
      data.id,
      data.status,
      data.ppe,
      data.shareholding,
      new Person(
        data.personId,
        null,
        null,
        null,
        personType,
        data.gerant,
        data.title,
        data.firstName,
        data.lastName,
        data.maidenName,
        data.birthdate,
        data.birthCity,
        data.birthCityForeign,
        data.birthCountry,
        data.nationality,
        data.ppe,
        data.ppeCategory,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        new Address(
          data.addressId,
          null,
          null,
          null,
          data.title || '',
          data.firstName  || '',
          data.lastName  || '',
          data.maidenName  || '',
          data.addressFirstLine || '',
          data.addressSecondLine  || '',
          data.addressThirdLine  || '',
          data.zipcode  || '',
          data.city || null,
          data.country || null,
          false,
          null,
          null
        ),
        null,
        null,
        null
      )
    )

    if (openForm.undivided) {
      dispatch(updateUndivided(undivided))
      dispatch(setOpenPartnerAddAddressEvent({show: false, address: null}))
    } else {
      dispatch(addUndivided(undivided))
    }
    dispatch(setOpenAccountUndividedForm({show: false, undivideds: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-undivideds-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  return (
    <>
      <div className={`overlay ${getClassForOverlay(openForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openForm.show ? 'sidebar--active' : ''}`}>
        <FormProvider {...methods} >
          <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
            <div className="sidebar__content">
              <div className="title">{t('partner.form.undivideds.add')}</div>
              <div className="form-bloc__form flex-container">
                <CheckboxToggle id={`gerant`}
                                name={`gerant`}
                                classes="col-md-12"
                                register={register}
                                label={prospect && prospect.moralType === 'business' ? t('account.form.beneficial-owner.gerant') : t('account.form.beneficial-owner.gerant-indivisaire')}
                />
                {prospect && prospect.prospectType === 'moral' &&
                  <InputView classes="col-md-12" label={t('account.form.beneficial-owner.code')} defaultValue={openForm.undivided?.person?.code || ''} readOnly={true} />
                }
                <SelectCustom classes="col-md-6"
                              label={t('account.form.beneficial-owner.civility')}
                              options={referential.global.title_physic || null}
                              id="title"
                              name="title"
                              register={register}
                />
                <SelectCustom classes="col-md-6"
                              label={t('partner.form.undivideds.status')}
                              options={referential.prospect.undivided_status || null}
                              id="status"
                              name="status"
                              register={register}
                              disabled={!openForm.undivided}
                />
                <Input id={`lastName`}
                       name={`lastName`}
                       label={t('partner.form.undivideds.lastname')}
                       register={register}
                       classes={`col-md-12`}
                       required={true}
                />
                {watchCivility === 'mrs' &&
                  <Input id={`maidenName`}
                         name={`maidenName`}
                         label={t('partner.form.undivideds.maiden-name')}
                         register={register}
                         classes={`col-md-12`}
                  />
                }
                <Input id={`firstName`}
                       name={`firstName`}
                       label={t('partner.form.undivideds.firstname')}
                       register={register}
                       classes={`col-md-12`}
                       required={true}
                />
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="birthdate"
                           className="form-control__label">{t('partner.form.undivideds.date-of-birth')}</label>
                    <div className="form-control__input">
                      <InputDate id="birthdate"
                                 name="birthdate"
                                 register={register}
                                 control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="birthCountry"
                           className="form-control__label">{t('partner.form.undivideds.country-of-birth')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name="birthCountry" defaultValue={getValues('birthCountry')}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="nationality"
                           className="form-control__label">{t('partner.form.undivideds.nationality')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name="nationality" defaultValue={getValues('nationality')}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <CityDatalist control={control}
                                setValue={setValue}
                                name="birthCity"
                                defaultValue={getValues('birthCity')}
                                labelContent={t('partner.form.undivideds.city-of-birth')}
                                country={getValues('birthCountry')}
                                classes="flex"
                                mode="col"
                  />
                </div>
                <Input id={`addressFirstLine`}
                       name={`addressFirstLine`}
                       label={t('partner.form.undivideds.address-first-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <Input id={`addressSecondLine`}
                       name={`addressSecondLine`}
                       label={t('partner.form.undivideds.address-second-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <Input id={`addressThirdLine`}
                       name={`addressThirdLine`}
                       label={t('partner.form.undivideds.address-third-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <CityAndPostcode control={control}
                                 nameCity="city"
                                 namePostcode="zipcode"
                                 country={getValues('country')}
                                 register={register}
                                 setValue={setValue}
                                 getValues={getValues}
                                 mode="row"
                                 classes="col-md-12 u-px0"
                />
                <div className="col-md-12">
                    <div className="form-control">
                        <label htmlFor="country"
                               className="form-control__label">{t('partner.form.undivideds.country')}</label>
                        <div className="form-control__input">
                            <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                        </div>
                    </div>
                </div>
                <CheckboxToggle id={`ppe`}
                                name={`ppe`}
                                classes="col-md-6"
                                register={register}
                                label={t('partner.form.undivideds.ppe')}
                />
                {watchPpe &&
                    <div className="col-md-12">
                        <div className="form-control--label-max-content">
                            <SelectCustom classes="" id="ppeCategory" name="ppeCategory" options={referential.customer.ppe_category} register={register} label={t('account.form.manager.managerPpeCategory')} />
                        </div>
                    </div>
                }
                <Input id={`shareholding`}
                       name={`shareholding`}
                       label={t('partner.form.undivideds.shareholding')}
                       register={register}
                       classes={`col-md-12`}
                />
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={() => handleClose()}>{t('common.cancel')}</button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default AddUndivided
