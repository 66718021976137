import {FunctionComponent, MouseEvent} from 'react'

import '../../../../../assets/styles/components/_alert.scss'
import expandIcon from "../../../../../assets/images/icons/arrow-bottom--dark.svg";
import Warning from "../../../../../assets/images/icons/Warning";
import { FieldValidationErrorInterface } from '../../../../../domain/Error/Error';

type Props = {
  title: string
  errors: FieldValidationErrorInterface[] | null
}

const FormFieldErrors: FunctionComponent<Props> = ({title, errors}) => {

  function handleCollapse(event: MouseEvent<HTMLElement>) {
    event.currentTarget.classList.toggle('collapse__title--active')
  }

  if (errors && errors?.length) {
    return (
      <div className="form-error-info-dropdown col-md-12">
        <div>
          <button type="button" className={`collapse__title-full`} onClick={handleCollapse}>
            <div className="form-error-info-dropdown--msg flex">
              <Warning />
              <span>{title}</span>
            </div>
            <span className="form-error-info-dropdown--number">{errors.length}</span>
            <img src={expandIcon} alt=""/>
          </button>
          <div className="collapse__content-full flex-container">
            <ul>
              {errors?.map((err) => {
                return (
                  <li>
                    {err.error}
                    {err.code && ` (${err.code})`}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  } else return null

}

export default FormFieldErrors