import dayjs from 'dayjs'

import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import {
  InvestorGatewayInterface,
  InvestorSheetListInterface,
  FilterInvestorSheetInterface
} from '../../domain/Distribution'
import { AddressInterface } from '../../domain/Distribution/Address'

interface InvestorApiInterface {
  accountCode: string
  accountType: string
  personType: string
  legalName: string
  iban: string|null
  address: AddressInterface
  email: string
  optinDividend: boolean
  optinAll: boolean
  extranet: boolean
}

export default class DistributionInvestorGateway implements InvestorGatewayInterface {
  async getList(
    accountId: string,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterInvestorSheetInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: InvestorSheetListInterface[] | null, nb_element: number } | null> {
    try {
      const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/investors/${accountId}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
          keyword: filter?.keyword || '',
          product: filter?.product?.label || '',
          term: filter?.term || '',
          periodicity: filter?.periodicity || '',
          paymentMethod: filter?.paymentMethod || ''
        },
        signal
      )
      if (!data) {
        return {
          data: [],
          nb_element: 0
        }
      }

      return {
        data: data.data.map((item: any) => {
          const subscriber = item.investors.filter((investor: InvestorApiInterface) => investor.personType === 'souscripteur')[0]
          const coSubscriber = item.investors.filter((investor: InvestorApiInterface) => investor.personType === 'co-souscripteur')[0]

          const subscriberAddressFormatted = subscriber && subscriber.address ? `${subscriber.address.address1 ?? ''} ${subscriber.address.address2 ?? ''} ${subscriber.address.address3 ?? ''} ${subscriber.address.city ?? ''} ${subscriber.address.postcode ?? ''} ${subscriber.address.country ?? ''}`.trim() : null
          const coSubscriberAddressFormatted = coSubscriber && coSubscriber.address ? `${coSubscriber.address.address1 ?? ''} ${coSubscriber.address.address2 ?? ''} ${coSubscriber.address.address3 ?? ''} ${coSubscriber.address.city ?? ''} ${coSubscriber.address.postcode ?? ''} ${coSubscriber.address.country ?? ''}`.trim() : null

          return {
            sessionId: item.sessionId,
            sessionMonth: item.sessionMonth,
            sessionYear: item.sessionYear,
            accountId: item.accountId,
            totalNbShare: item.totalNbShare,
            subscriber: {
              ...subscriber,
              addressFormatted: subscriberAddressFormatted
            },
            coSubscriber: {
              ...coSubscriber,
              addressFormatted: coSubscriberAddressFormatted
            },
            product: item.product,
            financialAmount: item.financialAmount,
            propertyAmount: item.propertyAmount,
            pl: item.pl,
            ps: item.ps,
            amount: item.amount,
            paymentDate: dayjs(item.paymentDate.date, 'YYYY-MM-DD HH:II:SS').format('DD/MM/YYYY') || null,
            paymentMethod: item.paymentMethod,
            paymentState: item.paymentState,
            sendMode: item.sendMode,
            sendDate: dayjs(item.sendDate.date, 'YYYY-MM-DD HH:II:SS').format('DD/MM/YYYY') || null,
            sendState: item.sendState
          }
        }),
        nb_element: data.nb_element
      }
    } catch (e) {
      return {
        data: [],
        nb_element: 0
      }
    }
  }
}
