import {Presenter} from '../Presenter'
import { SessionInvestorListInterface } from '../../domain/Distribution'

class SessionInvestorListPresenter extends Presenter {

  private useCase: {
    retrieveSessionInvestorList: { data: SessionInvestorListInterface[] | null, nb_element: number }
  }

  constructor(retrieveSessionInvestorList: any) {
    super({
      viewModel: {
        title: 'distribution.session.investor.search',
        heading: [
          {name: 'distribution.session.investor.table.heading.session-month', sort: 'session.month'},
          {name: 'distribution.session.investor.table.heading.session-year', sort: 'session.year'},
          {name: 'distribution.session.investor.table.heading.product-label', sort: 'product.label'},
          {name: 'distribution.session.investor.table.heading.product-periodicity', sort: 'product.distribution_periodicity'},
          {name: 'distribution.session.investor.table.heading.product-term', sort: 'product.distribution_term'},
          {name: 'distribution.session.investor.table.heading.product-iban'},
          {name: 'distribution.session.investor.table.heading.investor-code', sort: 'session_investor.account_code'},
          {name: 'distribution.session.investor.table.heading.investor-subscriber', sort: 'session_investor.account_code'},
          {name: 'distribution.session.investor.table.heading.investor-co-subscriber', sort: 'session_investor_co_souscripteur.account_code'},
          {name: 'distribution.session.investor.table.heading.partner-nb-parts', sort: 'session_line.nb_share'},
          {name: 'distribution.session.investor.table.heading.financial-amount', sort: 'cte_financial.value'},
          {name: 'distribution.session.investor.table.heading.property-amount', sort: 'cte_property.value'},
          {name: 'distribution.session.investor.table.heading.pl', sort: 'cte_withholding_tax.value'},
          {name: 'distribution.session.investor.table.heading.ps', sort: 'cte_social_contribution.value'},
          {name: 'distribution.session.investor.table.heading.amount-ttc', sort: 'cte_net.value'},
          {name: 'distribution.session.investor.table.heading.payment-date'},
          {name: 'distribution.session.investor.table.heading.payment-method'},
          {name: 'distribution.session.investor.table.heading.iban'},
          {name: 'distribution.session.investor.table.heading.payment-state'},
          {name: 'distribution.session.investor.table.heading.send-mode'},
          {name: 'distribution.session.investor.table.heading.send-date'},
          {name: 'distribution.session.investor.table.heading.send-state'},
          {name: 'distribution.session.investor.table.heading.address-subscriber'},
          {name: 'distribution.session.investor.table.heading.address-co-subscriber'},
          {name: 'distribution.session.investor.table.heading.address-email-subscriber'},
          {name: 'distribution.session.investor.table.heading.address-email-co-subscriber'},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    })

    this.useCase = {
      retrieveSessionInvestorList,
    }
  }

  async load() {
    try {
      this._setSessions(await this.useCase.retrieveSessionInvestorList)
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSessions(distributions: { data: SessionInvestorListInterface[] | null, nb_element: number }) {
    this.update({
      data: distributions.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': distributions.nb_element,
      }
    })
  }
}

export default SessionInvestorListPresenter
