import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenDatalistFilterDistributionSessionInvestor} from '../../../../store/component/event'
import blockScrollBody from '../../../util/BlockScroll'
import {
  ReferentielInterface,
} from "../../../../../domain/Referentiel/ReferentielInterface";
import {useForm, useFormState} from "react-hook-form";
import {usePrompt} from "../../../util/Navigation";
import getClassForOverlay from "../../../util/Sidebar";
import ProductDalalist from "../../Datalist/Product/ProductDalalist";
import SelectCustom from "../../Elements/Select";
import {FilterSessionInvestorInterface} from '../../../../../domain/Distribution'

const DistributionDatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterDistributionSessionInvestor = useAppSelector(
    (state) => state.event.openDatalistFilterDistributionSessionInvestor
  );

  const { register, control, handleSubmit, reset, setValue, getValues } = useForm<FilterSessionInvestorInterface>();

  useEffect(() => {
    blockScrollBody(openDatalistFilterDistributionSessionInvestor.show);
  }, [openDatalistFilterDistributionSessionInvestor]);

  useEffect(() => {
    setValue('keyword', openDatalistFilterDistributionSessionInvestor.filters.keyword)
    setValue('product', openDatalistFilterDistributionSessionInvestor.filters.product)
    setValue('term', openDatalistFilterDistributionSessionInvestor.filters.term)
    setValue('periodicity', openDatalistFilterDistributionSessionInvestor.filters.periodicity)
    setValue('paymentMethod', openDatalistFilterDistributionSessionInvestor.filters.paymentMethod)
  }, [openDatalistFilterDistributionSessionInvestor.filters]);

  const onConfirm = (data: any) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterDistributionSessionInvestor({
        show: false,
        count: openDatalistFilterDistributionSessionInvestor.count,
        filters: data,
      })
    );
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(
      setOpenDatalistFilterDistributionSessionInvestor( {
        show: false,
        filters: {
          product: {
            id: '',
            label: '',
            value: ''
          },
          term: '',
          periodicity: '',
          paymentMethod: ''
        }
      })
    );
  }

  return (
    <>
      { referential &&
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openDatalistFilterDistributionSessionInvestor.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterDistributionSessionInvestor.show ? "sidebar--active" : ""
            }`}
          >
            <form
              onSubmit={handleSubmit(onConfirm)}
              className="form-bloc form-bloc--partner-address-form"
            >
              <div className="sidebar__content">
                <div className="title">{t("common.filters")}</div>
                <div className="form-bloc__form flex-container">
                  <ProductDalalist classes="col-md-6"
                                   id="product"
                                   name="product"
                                   label={t('distribution.form.filter.product')}
                                   control={control}
                                   defaultValue={getValues('product') || ''}
                  />
                  <SelectCustom classes="col-md-6"
                                id="term"
                                name="term"
                                label={t('distribution.form.filter.term')}
                                options={referential.product.distribution_term}
                                register={register}
                  />
                  <SelectCustom classes="col-md-6"
                                id="periodicity"
                                name="periodicity"
                                label={t('distribution.form.filter.periodicity')}
                                options={referential.product.periodicity}
                                register={register}
                  />
                  <SelectCustom classes="col-md-6"
                                id="paymentMethod"
                                name="paymentMethod"
                                label={t('distribution.form.filter.payment-method')}
                                options={referential.prospect.payment_method}
                                register={register}
                  />
                  <footer className="sidebar__footer">
                      <button type="submit" className="button button--ink-2 u-mrm">
                        {t("common.save")}
                      </button>
                      <button
                          type="button"
                          className="button button--ink-2 button--ink-2--outline"
                          onClick={() => handleClose()}
                      >
                        {t("common.cancel")}
                      </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  );
}

export default DistributionDatalistFilter
