import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenDatalistFilterMovement} from '../../../../store/component/event'
import blockScrollBody from '../../../util/BlockScroll'
import {
  ReferentielInterface,
} from "../../../../../domain/Referentiel/ReferentielInterface";
import {useForm, useFormState} from "react-hook-form";
import {usePrompt} from "../../../util/Navigation";
import {FilterPreSubscriptionInterface} from "../../../../../domain/PreSubscription/PreSubscriptionList";
import getClassForOverlay from "../../../util/Sidebar";
import ProductDalalist from "../../Datalist/Product/ProductDalalist";
import MultiSelectCustom from "../../Elements/MultiSelect";
import SelectCustom from "../../Elements/Select";
import UserDatalist from "../../Datalist/UsersDatalist";

type Props = {
  investorId?: string|null
}

const MovementDatalistFilter: FunctionComponent<Props> = ({investorId}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterMovement = useAppSelector(
    (state) => state.event.openDatalistFilterMovement
  );

  const { register, control, handleSubmit, reset, setValue, getValues } = useForm<FilterPreSubscriptionInterface>();

  useEffect(() => {
    blockScrollBody(openDatalistFilterMovement.show);
  }, [openDatalistFilterMovement]);

  useEffect(() => {
    setValue('product', openDatalistFilterMovement.filters.product)
    setValue('transactionType', openDatalistFilterMovement.filters.transactionType)
    setValue('status', openDatalistFilterMovement.filters.status)
    setValue('tags', openDatalistFilterMovement.filters.tags)
    setValue('propertyType', openDatalistFilterMovement.filters.propertyType)
    setValue('paymentMode', openDatalistFilterMovement.filters.paymentMode)
    setValue('user', openDatalistFilterMovement.filters.user)
    setValue('name', openDatalistFilterMovement.filters.name)
    setValue('keywords', openDatalistFilterMovement.filters.keywords)
    setValue('source', openDatalistFilterMovement.filters.source)
    setValue('investor_id', investorId ?? null)
  }, [openDatalistFilterMovement.filters]);

  const onConfirm = (data: any) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterMovement({
        show: false,
        count: openDatalistFilterMovement.count,
        filters: data,
      })
    );
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(
      setOpenDatalistFilterMovement({
        show: false,
        filters: {...openDatalistFilterMovement.filters}
      })
    );
  }

  return (
    <>
      { referential &&
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openDatalistFilterMovement.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterMovement.show ? "sidebar--active" : ""
            }`}
          >
            <form
              onSubmit={handleSubmit(onConfirm)}
              className="form-bloc form-bloc--partner-address-form"
            >
              <div className="sidebar__content">
                <div className="title">{t("common.filters")}</div>
                <div className="form-bloc__form flex-container">
                  <ProductDalalist classes="col-md-6"
                                   id="product"
                                   name="product"
                                   label={t('movement.filter.product')}
                                   control={control}
                                   defaultValue={getValues('product')}
                  />
                  <SelectCustom classes="col-md-6"
                                id="transactionType"
                                name="transactionType"
                                label={t('movement.filter.transaction-type')}
                                options={referential.wallet_subscription.transaction_type}
                                register={register}
                                noChoiceOption
                  />
                  <MultiSelectCustom
                      id="status"
                      name="status"
                      classes="col-md-6"
                      control={control}
                      label={t("movement.filter.status")}
                      options={referential?.wallet.status || []}
                      customOnChange={ (options) => {setValue('status', options)}}
                  />
                  <MultiSelectCustom
                      id="tags"
                      name="tags"
                      classes="col-md-6"
                      control={control}
                      label={t("movement.filter.tags")}
                      options={referential?.transaction.tag || []}
                      customOnChange={ (options) => {setValue('tags', options)}}
                  />
                  <MultiSelectCustom
                      id="propertyType"
                      name="propertyType"
                      classes="col-md-6"
                      control={control}
                      label={t("movement.filter.property-type")}
                      options={referential?.wallet_subscription.property_type || []}
                      customOnChange={ (options) => {setValue('propertyType', options)}}
                  />
                  <SelectCustom classes="col-md-6"
                                id="paymentMode"
                                name="paymentMode"
                                label={t('movement.filter.payment-mode')}
                                options={referential.wallet_subscription.payment_method}
                                register={register}
                                noChoiceOption
                  />
                  <div className="col-md-6">
                      <UserDatalist label={t('movement.filter.user')} control={control} name="user"/>
                  </div>
                  <SelectCustom classes="col-md-6"
                                id="source"
                                name="source"
                                label={t('movement.filter.source')}
                                options={referential.transaction.source}
                                register={register}
                  />
                  <footer className="sidebar__footer">
                      <button type="submit" className="button button--ink-2 u-mrm">
                        {t("common.save")}
                      </button>
                      <button
                          type="button"
                          className="button button--ink-2 button--ink-2--outline"
                          onClick={() => handleClose()}
                      >
                        {t("common.cancel")}
                      </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  );
}

export default MovementDatalistFilter
