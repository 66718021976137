import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useParams } from 'react-router-dom';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppSelector } from '../../../../../store/hook';

import { ProspectInterface } from '../../../../../../domain/Prospect/Prospect';
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface';
import { CommunicationInterface } from '../../../../../../domain/Prospect/Communication';
import { FiscalityInterface } from '../../../../../../domain/Prospect/Fiscality';
import InitializeProspect from '../../../../../../useCase/Prospect/Initialization/InitializeProspect';
import ReferentielGateway from '../../../../../../gateway/Referentiel/ReferentielGateway';
import InitializePerson from '../../../../../../useCase/Prospect/Initialization/InitializePerson';
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase';
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway';
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase';
import CityDatalist from '../../../Datalist/City/CityDatalist';
import { City } from '../../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../../domain/Referentiel/Country/Country';
import CountryDatalist from '../../../Datalist/Country/CountryDatalist';
import Input from '../../../Elements/Input';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import { toastError, toastSuccess } from '../../../../util/Toast';
import { usePrompt } from '../../../../util/Navigation';
import SelectCustom from '../../../Elements/Select';
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements';
import InputDate from '../../../Elements/InputDate';
import FormErrorInfo from "../../../Alert/FormErrorInfo/FormErrorInfo";
import CheckboxToggle from '../../../Elements/CheckboxToggle';
import DocumentUtils from "../../../../util/DocumentUtils";
import DocumentGateway from "../../../../../../gateway/Prospect/Document/DocumentGateway";
import DocumentForm from "../../Document/Form";
import FooterBlock from "../../../Block/FooterBlock";
import Checkbox from '../../../Elements/Checkbox';
import DocumentHistoryBlock from "../../../Block/DocumentHistoryBlock";
import {DocumentInterface, DocumentType} from "../../../../../../domain/Document/DocumentInterface";

interface IFormInput {
  prospectCode: string | null;
  externalDepositaryCode: string | null;
  externalCode: string | null;
  recoveryCode: string | null;
  moralType: string | null;
  legalStatus: string | null;
  companyName: string | null;
  siret: string | null;

  managerLastname: string | null;
  managerFirstname: string | null;
  managerPpe: boolean | null;
  managerPpeCategory: string | null;
  managerBirthdate: string | null;
  managerBirthCity: City | null;
  managerBirthCityForeign: string | null;
  managerNationality: Country | null;
  managerBirthCountry: Country | null;
  managerExtranet: string | null;
  managerExtranetCreatedAt: string | null;

  fiscality: FiscalityInterface | null;

  kycCategoryValue: string | null;
  kycCategoryDate: string | null;
  kycLabft: string | null;
  kycLabftDate: string | null;
  kycProfession: string | null;
  kycFatca: boolean | null;

  optinMarketing: CommunicationInterface | null;
  optinDividend: CommunicationInterface | null;
  optinFiscality: CommunicationInterface | null;
  optinGeneralAssembly: CommunicationInterface | null;
  optinInvitation: CommunicationInterface | null;
  optinAll: CommunicationInterface | null;

  personEmail: string | null;
  personPhone: string | null;

  statusCreatedAt: string | null;
  status: string | null;
  exitedAt: string | null;
  blockedAt: string | null;
  fiscalityIncomeTax: string | null;
  fiscalityBusinessTaxt: string | null;
  fiscalitySubjectIfi: string | null;

  documents: {
    [key: string] : {
      [key: string] : {
        date: string
        updatedAt: string
      }
    }
  }
  distributionPaymentPeriodicity: string | null
}

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  referential: ReferentielInterface
  prospect?: ProspectInterface
  isLectureMode?: boolean
  handler?: any
}

const GeneralInformationMoralPerson: FunctionComponent<Props> = ({ prospect, isLectureMode, handler, referential, setStatus }) => {
  const { t } = useTranslation();
  const { prospectType } = useParams();
  const navigate = useNavigate();

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const prospectStore = useAppSelector((state) => state.prospect);

  const [formErrors, setFormErrors] = useState<string[]>([])
  const [documentArray, setDocumentArray] = useState<DocumentType>({});
  const [documentHistoryArray, setDocumentHistoryArray] = useState<DocumentInterface[]>([])
  const [defaultCountry, setDefaultCountry] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const documentUtils = new DocumentUtils()

  useEffect(() => {
    if (prospect) {
      setValue('prospectCode', prospect.prospectCode);
      setValue('externalDepositaryCode', prospect.externalDepositaryCode);
      setValue('externalCode', prospect.externalCode);
      setValue('moralType', prospect.moralType);
      setValue('recoveryCode', prospect.recoveryCode);
      setValue('legalStatus', prospect.legalStatus);
      setValue('companyName', prospect.companyName);
      setValue('siret', prospect.siret);
      setValue('fiscality', (prospect && prospect.persons && prospect.persons.length > 0) ? prospect.persons[0].fiscality : null);
      setValue('kycCategoryValue', prospect.kycCategoryValue);
      setValue('kycCategoryDate', prospect.kycCategoryDate);
      setValue('kycLabft', prospect.kycLabft);
      setValue('kycLabftDate', prospect.kycLabftDate);
      setValue('kycProfession', prospect.kycProfession);
      setValue('kycFatca', prospect.kycFatca);
      setValue('statusCreatedAt', prospect.createdAt);
      setValue('status', prospect.status);
      setValue('exitedAt', prospect.exitedAt);
      setValue('blockedAt', prospect.blockedAt);
      setValue("distributionPaymentPeriodicity", prospect.distributionPaymentPeriodicity)

      if (prospect.persons !== null && prospect.persons[0]) {
        setValue('managerLastname', prospect.persons[0].lastname);
        setValue('managerFirstname', prospect.persons[0].firstname);
        setValue('managerPpe', prospect.persons[0].ppe);
        setValue('managerPpeCategory', prospect.persons[0].ppeCategory);
        setValue('managerBirthdate', prospect.persons[0].birthdate);
        setValue('managerBirthCity', prospect.persons[0].birthCity);
        setValue('managerBirthCityForeign', prospect.persons[0].birthCityForeign);
        setValue('managerExtranet', prospect.persons[0].extranet ? '1' : '0');
        setValue('managerExtranetCreatedAt', prospect.persons[0].extranetCreatedAt);

        setValue('optinMarketing', prospect.persons[0].optinMarketing);
        setValue('optinDividend', prospect.persons[0].optinDividend);
        setValue('optinFiscality', prospect.persons[0].optinFiscality);
        setValue('optinGeneralAssembly', prospect.persons[0].optinGeneralAssembly);
        setValue('optinInvitation', prospect.persons[0].optinInvitation);

        setValue('personEmail', prospect.persons[0].email);
        setValue('personPhone', prospect.persons[0].phone);

        setValue('fiscality', prospect.persons[0].fiscality);
        if (prospect.persons[0].fiscality !== null && prospect.persons[0].fiscality.incomeTax) {
          setValue('fiscalityIncomeTax', '1');
        } else {
          setValue('fiscalityIncomeTax', '0');
        }

        if (prospect.persons[0].fiscality !== null && prospect.persons[0].fiscality.businessTax) {
          setValue('fiscalityBusinessTaxt', '1');
        } else {
          setValue('fiscalityBusinessTaxt', '0');
        }

        if (prospect.persons[0].fiscality !== null && prospect.persons[0].fiscality.subjectIfi) {
          setValue('fiscalitySubjectIfi', '1');
        } else {
          setValue('fiscalitySubjectIfi', '0');
        }
      }
    }

    setValue('fiscality.taxResidency', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].fiscality && prospect.persons[0].fiscality.taxResidency) ? prospect.persons[0].fiscality?.taxResidency : 'france');
    setValue('fiscalityBusinessTaxt', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].fiscality && prospect.persons[0].fiscality) ? (prospect.persons[0].fiscality.businessTax) ? '1' : '0' : '1');
    setValue('fiscalityIncomeTax', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].fiscality && prospect.persons[0].fiscality) ? (prospect.persons[0].fiscality.incomeTax) ? '1' : '0' : '0');
    setValue('fiscalitySubjectIfi', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].fiscality && prospect.persons[0].fiscality) ? (prospect.persons[0].fiscality.subjectIfi) ? '1' : '0' : '0');
  }, [prospect]);

  useEffect(() => {
    if (countryByDefault) {
      if (prospect) {
        setValue('managerBirthCountry', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].birthCountry) ? prospect.persons[0].birthCountry : countryByDefault[0]);
        setValue('managerNationality', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].nationality) ? prospect.persons[0].nationality : countryByDefault[0]);
        // setValue('managerBirthCity', (prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].birthCity) ? prospect.persons[0].birthCity : null)
      } else {

        setValue('managerBirthCountry', countryByDefault[0]);
        setValue('managerNationality', countryByDefault[0]);
      }
    }
  }, [countryByDefault, prospect]);

  useEffect( () => {
    if (prospect && prospect.id) {
      const documentGateway = new DocumentGateway(prospect.id)
      documentGateway.getFiles().then((response) => {
        if (response) {
          setDocumentArray(response)
        }
      })

      documentGateway.getHistory().then((response) => {
        if (response) {
          setDocumentHistoryArray(response)
        }
      })
    }
  }, [openUploadForm])

  useEffect(() => {
    if (prospect && prospect.persons) {
      prospect.persons.map((person) => {
        if (person.id && documentArray[person.id]) {
          documentArray[person.id].map((doc: DocumentInterface) => {
            setValue(`documents.${person.id}.${doc.type}.date`, doc.date)
            setValue(`documents.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
          })
        }
      })
    }
  }, [documentArray])

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();

  const watchStatus = watch('status')
  const watchLegalStatus = watch('legalStatus')
  const watchCompanyName = watch('companyName')
  const watchSiret = watch('siret')
  const watchManagerLastname = watch('managerLastname')
  const watchManagerFirstname = watch('managerFirstname')
  const watchManagerBirthdate = watch('managerBirthdate')
  const watchManagerNationality = watch('managerNationality')
  const watchManagerBirthCity = watch('managerBirthCity')
  const watchManagerBirthCountry = watch('managerBirthCountry')


  useEffect(() => {
    checkValidity()
  }, [
    watchStatus,
    watchLegalStatus,
    watchCompanyName,
    watchSiret,
    watchManagerLastname,
    watchManagerFirstname,
    watchManagerBirthdate,
    watchManagerNationality,
    watchManagerBirthCity,
    watchManagerBirthCountry
  ])

  const checkValidity = () => {
    const errors = []
    if (!watchStatus) {
      errors.push('account.form.status.status')
    }
    if (!watchLegalStatus) {
      errors.push('account.form.general-information.legalStatus')
    }
    if (!watchCompanyName) {
      errors.push('account.form.general-information.companyName')
    }
    if (!watchSiret) {
      errors.push('account.form.general-information.siret')
    }
    if (!watchManagerLastname) {
      errors.push('account.form.manager.managerLastname')
    }
    if (!watchManagerFirstname) {
      errors.push('account.form.manager.managerFirstname')
    }
    if (!watchManagerBirthdate) {
      errors.push('account.form.manager.managerBirthdate')
    }
    if (!watchManagerNationality) {
      errors.push('account.form.manager.managerNationality')
    }
    if (!watchManagerBirthCity) {
      errors.push('account.form.manager.managerBirthCity')
    }
    if (!watchManagerBirthCountry) {
      errors.push('account.form.general-information.personBirthCountry')
    }
    setFormErrors(errors);
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        // if (handler) {
        //   handler(prospect)
        // }
        return (<ConfirmationModal onConfirm={ () => onConfirm(data) } onClose={ onClose }/>);
      },
    });
  };
  const onConfirm = (data: any) => {
    reset(data);
    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspectMoral();
    }

    if (null == prospect.prospectType) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'moral';
    }

    prospect.prospectCode = data.prospectCode;
    prospect.externalDepositaryCode = data.externalDepositaryCode;
    prospect.externalCode = data.externalCode;
    prospect.recoveryCode = data.recoveryCode;
    prospect.moralType = data.moralType;
    prospect.legalStatus = data.legalStatus;
    prospect.companyName = data.companyName;
    prospect.siret = data.siret;
    prospect.siret = data.siret;
    prospect.distributionPaymentPeriodicity = data.distributionPaymentPeriodicity

    if (prospectStore !== null && prospectStore.partner !== null && prospectStore.partner.id !== null) {
      prospect.partner = prospectStore.partner;
    }

    let manager = new InitializePerson().initializePerson();

    if (prospect.persons !== null && prospect.persons[0]) {
      manager = prospect.persons[0];
    }

    manager.gerant = true;
    manager.firstname = data.managerFirstname;
    manager.lastname = data.managerLastname;
    manager.ppe = data.managerPpe;
    manager.ppeCategory = data.managerPpeCategory;
    manager.birthdate = data.managerBirthdate;
    manager.birthCity = data.managerBirthCity;
    manager.birthCityForeign = data.managerBirthCityForeign;
    manager.birthCountry = data.managerBirthCountry;
    manager.nationality = data.managerNationality;
    manager.extranet = data.managerExtranet == '1';
    manager.extranetCreatedAt = data.managerExtranetCreatedAt;
    manager.optinMarketing = data.optinMarketing;
    manager.optinDividend = data.optinDividend;
    manager.optinFiscality = data.optinFiscality;
    manager.optinGeneralAssembly = data.optinGeneralAssembly;
    manager.optinInvitation = data.optinInvitation;
    manager.fiscality = data.fiscality;
    manager.email = data.personEmail;
    manager.phone = data.personPhone;

    if (manager.id && data.documents[manager.id]) {
      manager.documents = documentUtils.getDocumentsForPerson(data.documents[manager.id], documentArray, manager.id)
    }

    if (manager.fiscality) {
      if (data.fiscalityIncomeTax == '1') {
        manager.fiscality.incomeTax = true;
      } else {
        manager.fiscality.incomeTax = false;
      }

      if (data.fiscalityBusinessTaxt == '1') {
        manager.fiscality.businessTax = true;
      } else {
        manager.fiscality.businessTax = false;
      }

      if (data.fiscalitySubjectIfi == '1') {
        manager.fiscality.subjectIfi = true;
      } else {
        manager.fiscality.subjectIfi = false;
      }
    }

    if (prospect.persons !== null && prospect.persons[0] && prospect.persons[0].firstname && prospect.persons[0].lastname) {
      prospect.persons[0] = manager;
    } else if (manager.firstname && manager.lastname) {
      prospect.persons = [manager];
    } else {
      prospect.persons = null;
    }

    prospect.kycCategoryValue = data.kycCategoryValue;
    prospect.kycCategoryDate = data.kycCategoryDate;
    prospect.kycLabft = data.kycLabft;
    prospect.kycLabftDate = data.kycLabftDate;
    prospect.kycProfession = data.kycProfession;
    prospect.kycFatca = data.kycFatca;

    prospect.status = data.status;
    prospect.exitedAt = data.exitedAt;
    prospect.blockedAt = data.blockedAt;

    if (null !== prospect.id) {
      setIsLoading(true)
      new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'));
          if(watchStatus) {
            setStatus(watchStatus)
          } else {
            setStatus('')
          }
        } else {
          toastError(t('account.notify.update-error'));
        }
      })
      .catch(error => {
        toastError(t('account.notify.update-error'));
      })
      .finally(() => {
        setIsLoading(false)
      });
    } else {
      setIsLoading(true)
      new AddUseCase(new ProspectGateway())
        .execute(prospect)
        .then(uuid => {
          if (null !== uuid) {
            toastSuccess(t('account.notify.add-success'))
            navigate(`/${t('url.prospect.edit-general-information')}/${uuid}`)

          } else {
            toastError(t('account.notify.add-error'));
          }
          setIsLoading(false)
        })
        .catch(error => {
          toastError(t('account.notify.add-error'));
        })
        .finally(() => {
          setIsLoading(false)
        });
    }
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const watchOptinAll = watch('optinAll.type');
  useEffect(() => {
    if (watchOptinAll) {
      setValue('optinMarketing.type', watchOptinAll);
      setValue('optinFiscality.type', watchOptinAll);
      setValue('optinInvitation.type', watchOptinAll);
      setValue('optinGeneralAssembly.type', watchOptinAll);
      setValue('optinDividend.type', watchOptinAll);
    }
  }, [watchOptinAll]);


  const watchManagerPpe = watch('managerPpe');
  useEffect(() => {
    if (!watchManagerPpe) {
      setValue('managerPpeCategory', '');
    }
  }, [watchManagerPpe]);

  const watchMoralType = watch('moralType');
  useEffect(() => {
    if (prospect) {
      prospect.moralType = getValues('moralType');
      if (handler) {
        handler(prospect);
      }
    }
  }, [watchMoralType]);

  function isTypeDisabled(): boolean {
    if (prospect) {
      if (prospect.undivideds && watchMoralType === 'joint_possession') {
        return prospect.undivideds.length > 0;
      }
      if (prospect.beneficialOwners && watchMoralType === 'business') {
        return prospect.beneficialOwners.length > 0;
      }
      if (prospect.moralLegalRepresentatives && watchMoralType === 'other') {
        return prospect.moralLegalRepresentatives.length > 0;
      }
    }
    return false;
  }

  const immatriculationBlock = <div className={ `form-bloc form--bloc--immatriculation` }>
    <div className="form-bloc__title">{ t('account.form.immatriculation.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="prospectCode" className="form-control__label">{ t('account.form.immatriculation.prospectCode') }</label>
          <div className="form-control__input">
            <input type="text" id="prospectCode" { ...register('prospectCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalDepositaryCode"
                 className="form-control__label">{ t('account.form.immatriculation.externalDepositaryCode') }</label>
          <div className="form-control__input">
            <input type="text" id="externalDepositaryCode" { ...register('externalDepositaryCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalCode" className="form-control__label">{ t('account.form.immatriculation.externalCode') }</label>
          <div className="form-control__input">
            <input type="text" id="externalCode" { ...register('externalCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label">{ t('account.form.immatriculation.recoveryCode') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('recoveryCode') } disabled={ true }/>
          </div>
        </div>
      </div>
    </div>
  </div>;
  const generalInformationBlock = <div className={ `form-bloc form--bloc--general-information` }>
    <div className="form-bloc__title">{ t('account.form.general-information.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="moralType" className="form-control__label">{ t('account.form.general-information.moralType') }</label>
          <div className="form-control__input">
            <select { ...register('moralType') } disabled={ isTypeDisabled() }>
              { referential && referential.prospect.moral_type.map((item, index) => {
                return ( <option key={ index } value={item.value}> {item.label} </option> )
              })}
            </select>
          </div>
        </div>
      </div>
      { referential &&
          <SelectCustom classes="col-md-6" valueClasses={!watchLegalStatus ? "field-in-error" : ""} id="legalStatus" name="legalStatus" options={ referential.global.title_moral }
                        register={ register } label={ t('account.form.general-information.legalStatus') } labelClasses="mendatory-field"
        />
      }
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label mendatory-field">{ t('account.form.general-information.companyName') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('companyName') } className={!watchCompanyName ? 'field-in-error' : ''} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label mendatory-field">{ t('account.form.general-information.siret') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('siret') } className={!watchSiret ? 'field-in-error' : ''} />
          </div>
        </div>
      </div>
    </div>
  </div>;
  const managerBlock = <div className={ `form-bloc form--bloc--general-information` }>
    <div className="form-bloc__title">{ t('account.form.manager.title') }</div>
    <div className="flex-container">
      <div className="col-md-12">
        <div className="form-bloc__form flex-container">
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthdate" className="form-control__label mendatory-field">{ t('account.form.manager.managerLastname') }</label>
              <div className="form-control__input">
                <input type="text" { ...register('managerLastname') } className={!watchManagerLastname ? 'field-in-error' : ''}/>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthdate" className="form-control__label mendatory-field">{ t('account.form.manager.managerBirthdate') }</label>
              <div className="form-control__input">
                <InputDate id="managerBirthdate"
                           name="managerBirthdate"
                           classes={!watchManagerBirthdate ? 'field-in-error' : ''}
                           register={ register }
                           control={ control }
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthCity" className="form-control__label mendatory-field">{ t('account.form.manager.managerFirstname') }</label>
              <div className="form-control__input">
                <input type="text" { ...register('managerFirstname') } className={!watchManagerFirstname ? 'field-in-error' : ''}/>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <CityDatalist setValue={ setValue }
                          classes={!watchManagerBirthCity ? 'field-in-error' : ''}
                          control={ control }
                          name={ 'managerBirthCity' }
                          labelContent={ t('account.form.manager.managerBirthCity') }
                          mode={ 'row' }
                          country={ getValues('managerBirthCountry') }
                          disabled={isLectureMode}
                          defaultValue={ getValues('managerBirthCity') }
            />
          </div>

          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="managerNationality" className="form-control__label mendatory-field">{ t('account.form.manager.managerNationality') }</label>
              <div className="form-control__input">
                <CountryDatalist control={ control }isOnError={!watchManagerNationality} name="managerNationality" defaultValue={ getValues('managerNationality') }
                                 disabled={ isLectureMode }/>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <CheckboxToggle id={'managerPpe'} name={'managerPpe'} register={register} label={t('account.form.manager.managerPpe')}></CheckboxToggle>
          </div>
          <div className="col-md-3">
            { referential && watchManagerPpe &&
              <SelectCustom classes="" id="managerPpeCategory" name="managerPpeCategory"
                            options={ referential.customer.ppe_category } register={ register }
                            label={ t('account.form.manager.managerPpeCategory') }/>
            }
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="managerBirthCountry"
                     className="form-control__label mendatory-field">{ t('account.form.general-information.personBirthCountry') }</label>
              <div className="form-control__input">
                <CountryDatalist control={ control }isOnError={!watchManagerBirthCountry} name="managerBirthCountry" defaultValue={ getValues('managerBirthCountry') }
                                 disabled={ isLectureMode }/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  const kycBlock = <div className={ `form-bloc form--bloc--kyc` }>
    <div className="form-bloc__title">{ t('account.form.kyc.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycCategoryValue" className="form-control__label">{ t('account.form.kyc.kycCategoryValue') }</label>
          <div className="form-control__input">
            <select { ...register('kycCategoryValue') }>
              <option value=""/>
              { referential?.prospect.kyc_category_value.map((item, index) => <option key={ index }
                                                                                                 value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycCategoryDate" className="form-control__label">{ t('account.form.kyc.kycCategoryDate') }</label>
          <div className="form-control__input">
            <InputDate id="kycCategoryDate"
                       name="kycCategoryDate"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycLabft" className="form-control__label">{ t('account.form.kyc.kycLabft') }</label>
          <div className="form-control__input">
            <select { ...register('kycLabft') }>
              <option value=""/>
              { referential?.prospect.kyc_labft.map((item, index) => <option key={ index }
                                                                                        value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycLabftDate" className="form-control__label">{ t('account.form.kyc.kycLabftDate') }</label>
          <div className="form-control__input">
            <InputDate id="kycLabftDate"
                       name="kycLabftDate"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycProfession" className="form-control__label">{ t('account.form.kyc.kycProfession') }</label>
          <div className="form-control__input">
            <select { ...register('kycProfession') }>
              <option value=""/>
              { referential?.global.profession.map((item, index) => <option key={ index }
                                                                                       value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <Checkbox id={'kycFatca'} name={'kycFatca'} register={register} label={t('account.form.kyc.kycFatca')}/>
      </div>
    </div>
  </div>;
  const fiscalityBlock = <div className={ `form-bloc form--bloc--fiscality` }>
    <div className="form-bloc__title">{ t('account.form.fiscality.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="personFirstFiscalityTaxResidency"
                  className="form-control__label">{ t('account.form.fiscality.taxResidency') }</label>
            <div className="form-control__input">
              <select { ...register('fiscality.taxResidency') } id="personFirstFiscalityTaxResidency">
                <option value=""/>
                { referential?.prospect.tax_residency.map((item, index) => <option key={ index }
                                                                                              value={ item.value }>{ item.label }</option>) }
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="personFirstFiscalityRate" className="form-control__label">{ t('account.form.fiscality.rate') }</label>
            <div className="form-control__input">
              <select { ...register('fiscality.rate') } id="personFirstFiscalityRate">
                <option value=""/>
                { referential?.prospect.nr_rate.map((item, index) => <option key={ index }
                                                                                        value={ item.value }>{ item.label }</option>) }
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="businessTax" className="form-control__label">{ t('account.form.fiscality.businessTax') }</label>
            <div className="form-control form-control--label-auto">
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="1" { ...register('fiscalityBusinessTaxt') } />
                  { t('common.yes') }
                </label>
              </div>
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="0" { ...register('fiscalityBusinessTaxt') } />
                  { t('common.no') }
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="businessTax" className="form-control__label">{ t('account.form.fiscality.incomeTax') }</label>
            <div className="form-control form-control--label-auto">
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="1" { ...register('fiscalityIncomeTax') } />
                  { t('common.yes') }
                </label>
              </div>
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="0" { ...register('fiscalityIncomeTax') } />
                  { t('common.no') }
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="subjectIfi" className="form-control__label">{ t('account.form.fiscality.subjectIfi') }</label>
            <div className="form-control form-control--label-auto">
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="1" { ...register('fiscalitySubjectIfi') } />
                  { t('common.yes') }
                </label>
              </div>
              <div className="form-control__input">
                <label className="form-label form-label--input-radio">
                  <input type="radio" value="0" { ...register('fiscalitySubjectIfi') } />
                  { t('common.no') }
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="ifi" className="form-control__label">{ t('account.form.fiscality.ifi') }</label>
            <div className="form-control__input">
              <input type="text" id="ifi" { ...register('fiscality.ifi') }/>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="lei" className="form-control__label">{ t('account.form.fiscality.lei') }</label>
            <div className="form-control__input">
              <input type="text" id="lei" { ...register('fiscality.lei') }/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;

  const distributionBlock = <div className={`form-bloc form--bloc--distribution`}>
    <div className="form-bloc__title">{t('account.form.distribution.title')}</div>
    <div className="form-bloc__form ">
      <div className="flex-container">
          <SelectCustom classes="col-md-12" label={t('account.form.distribution.paymentMethod')}
                        id={`fiscality.paymentMethod`}
                        name={`fiscality.paymentMethod`}
                        options={referential.prospect.payment_method || []}
                        register={register}
            />
          <SelectCustom classes="col-md-12" label={t('account.form.distribution.paymentPeriodicity')}
                        id={`distributionPaymentPeriodicity`}
                        name={`distributionPaymentPeriodicity`}
                        options={referential.prospect.payment_periodicity || []}
                        register={register}
            />
      </div>
    </div>
  </div>

  const communicationBlock = <div className={ `form-bloc form--bloc--communication` }>
    <div className="form-bloc__title">{ t('account.form.communication.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="businessTax" className="form-control__label">{ t('account.form.communication.personExtranet') }</label>
          <div className="form-control form-control--label-auto">
            <div className="form-control__input">
              <label className="form-label form-label--input-radio">
                <input type="radio" value="1" { ...register('managerExtranet') } />
                { t('common.yes') }
              </label>
            </div>
            <div className="form-control__input">
              <label className="form-label form-label--input-radio">
                <input type="radio" value="0" { ...register('managerExtranet') } />
                { t('common.no') }
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="lei" className="form-control__label">{ t('account.form.communication.personExtranetCreatedAt') }</label>
          <div className="form-control__input">
            <InputDate id="statusCreatedAt"
                       name="managerExtranetCreatedAt"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-3"/>
      <div className="col-md-3"/>
      <Input register={register}
             classes={'col-md-6'}
             label={t('account.form.communication.personEmail')}
             type={'email'}
             validator={'email'}
             name={'personEmail'}
             id={'personEmail'}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('account.form.communication.personPhone')}
             type={'tel'}
             validator={'tel'}
             name={'personPhone'}
             id={'personPhone'}
      />
      <div className="col-md-12">
      </div>
      <div className="col-md-6">
        <table className="table--communication">
          <thead>
          <tr>
            <th/>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <th key={ 'table' + uuidV4() } className="form-control__label">{ communication_type.label }</th>
              );
            }) }
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinMarketing') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinMarketing.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinDividend') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinDividend.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinFiscality') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinFiscality.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinGeneralAssembly') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinGeneralAssembly.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinInvitation') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinInvitation.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinAll') }</label>
            </td>
            { referential && referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinAll.type') } disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>;
  const documentsBlock = prospect?.persons?.map((person, index) => {
    if (prospect && prospect.id && person && person.id) {
      return (
        <>
          <div className="col-md-6">
            <DocumentForm
              title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${person.lastname + ' ' + person.firstname}`}
              name={`documents.${person.id}`}
              uuid={person.id}
              documentGateway={new DocumentGateway(prospect.id)}
              register={register}
              control={control}
              documentData={documentArray[person.id]}
              isLectureMode={isLectureMode}
            />
          </div>
        </>
      )
    }
  })
  const statusBlock = <div className={ `form-bloc form--bloc--status` }>
    <div className="form-bloc__title">{ t('account.form.status.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="statusCreatedAt" className="form-control__label">{ t('account.form.status.statusCreatedAt') }</label>
          <div className="form-control__input">
            <InputDate id="statusCreatedAt"
                       name="statusCreatedAt"
                       register={ register }
                       control={ control }
                       disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="status" className="form-control__label mendatory-field">{ t('account.form.status.status') }</label>
          <div className="form-control__input">
            <select { ...register('status') } className={!watchStatus ? 'field-in-error' : ''}>
              <option value=""/>
              { referential &&
                referential.prospect.status.map((item, index) => <option key={ index } value={ item.value }>{ item.label }</option>)
              }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="exitedAt" className="form-control__label">{ t('account.form.status.exitedAt') }</label>
          <div className="form-control__input">
            <InputDate id="exitedAt"
                       name="exitedAt"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="blockedAt" className="form-control__label">{ t('account.form.status.blockedAt') }</label>
          <div className="form-control__input">
            <InputDate id="blockedAt"
                       name="blockedAt"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
    </div>
  </div>;
  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node);
    }
  }, []);

  return (
    <form onSubmit={ handleSubmit(onSubmit) } ref={ measuredRef }>
      <div className="flex-container">
        <div className="col-md-12">
          {formErrors.length ? <FormErrorInfo errors={formErrors} message={t('account.notify.form-error-base-message')}/> : null}
        </div>
        <div className="col-md-12">{ immatriculationBlock }</div>
        <div className="col-md-12">{ generalInformationBlock }</div>
        <div className="col-md-12">{ managerBlock }</div>
        <div className="col-md-12">{ fiscalityBlock }</div>
        <div className="col-md-6">{ distributionBlock }</div>
        <div className="col-md-6">{ kycBlock }</div>
        <div className="col-md-12">{ communicationBlock }</div>
        {prospect && <div className="col-md-12">{ documentsBlock }</div>}
        <div className="col-md-12">
          {prospect && prospect.id && prospect.recoveryCode && documentHistoryArray && (documentHistoryArray.length > 0) &&
            <div className="form-bloc">
              <DocumentHistoryBlock documents={documentHistoryArray} gateway={new DocumentGateway(prospect.id)}/>
            </div>
          }
        </div>
        <div className="col-md-12">{ statusBlock }</div>
      </div>
      {!isLectureMode && <FooterBlock disabled={!watchStatus || isLoading} onCancelUrl={t('url.prospect.dashboard')}/>}
    </form>
  );
};

export default GeneralInformationMoralPerson;
