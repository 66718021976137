import { DismembermentLedgerListInterface, FilterDismembermentLedgerInterface } from './DismembermentLedgerList'

type DismembermentLedgerType = 'bare-owners' | 'usufructuaries'

export const DismembermentLedgerTypes = {
  BARE_OWNERS: 'bare-owners',
  USUFRUCTUARIES: 'usufructuaries',
} as const;

interface DismembermentLedgerGatewayInterface {
  getList(type: DismembermentLedgerType,
          currentPage: number,
          itemsPerPage: number,
          filter: FilterDismembermentLedgerInterface|null,
          signal?: AbortSignal,
          sort?: string,
          order?: string ): Promise<{data: DismembermentLedgerListInterface[]|null, numberOfItems: number}|null>

  match(bareOwners: string[], usufructuaries: string[]): Promise<string[]|null>
}

export type { DismembermentLedgerType, DismembermentLedgerGatewayInterface }
