import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import '../../../../../assets/styles/components/_alert.scss'

type Props = {
  classes?: string
  nbSelected: number
  nbSelectedMax: number
}

const AlertMatch: FunctionComponent<Props> = ({ classes, nbSelected, nbSelectedMax }) => {
  const {t} = useTranslation()

  if (nbSelectedMax === 0 && nbSelected === 0) {
    return null
  }

  let alertType = 'alert-info'
  let message = `${nbSelected} / ${nbSelectedMax}`

  if (nbSelected === 0 && nbSelectedMax > 0) {
    alertType = 'alert-error'
    message = t('alert.error.no-selection', {nbSelectedMax})
  } else if (nbSelected > 0 && nbSelected < nbSelectedMax) {
    alertType = 'alert-warning'
    message = t('alert.warning.selection', {count: nbSelected, max: nbSelectedMax})
  } else if (nbSelected === nbSelectedMax) {
    alertType = 'alert-success'
    message = t('alert.success.max-reached', {max: nbSelectedMax})
  }

  return (
    <div className={`alert-new ${alertType} ${classes ? classes : ''} u-mbm`} role="alert">
      <p className="alert-new__text">{message}</p>
    </div>
  )
}

export default AlertMatch
