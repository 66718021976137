import React, {FunctionComponent} from 'react';
import Input from "../../../Elements/Input";
import InputDate from "../../../Elements/InputDate";
import {useTranslation} from "react-i18next";
import MatchUrl from "../../../../../../domain/Utils/MatchedUrl";
import { TFieldErrors } from '../../Commissioning/types';

type TProps = {
  register: any
  control: any
  classes: string
  parentPath: string
  title: string
  fieldErrors: TFieldErrors
}

const SessionSetting: FunctionComponent<TProps> = ({register, control, classes, parentPath, title, fieldErrors}) => {
  const {t} = useTranslation()
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  return (
    <div className={classes}>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{title}</div>
        <div className="form-bloc__form flex-container">
            <div className="col-md-4">
              <Input register={register}
                     label={t('commissioning.form.setting.session.sessionNb')}
                     type={'text'}
                     name={`${parentPath || ""}sessionNb`}
                     id={`${parentPath || ""}sessionNb`}
                     readOnly={true}
                     classes="u-mbs"
              />
            </div>

            <div className="col-md-4">
              <Input register={register}
                     label={t('commissioning.form.setting.session.sessionStatut')}
                     type={'text'}
                     name={`${parentPath || ""}sessionStatut`}
                     id={`${parentPath || ""}sessionStatut`}
                     readOnly={true}
                     classes="u-mbs"
              />
            </div>

            <div className="col-md-4">
              <InputDate id={`${parentPath || ""}sessionPayment`}
                         name={`${parentPath || ""}sessionPayment`}
                         label={t('commissioning.form.setting.session.sessionPayment')}
                         register={register}
                         control={control}
                         classes="u-mbs mandatory-field"
                         readOnly={isLectureMode}
                         dynamicError={fieldErrors.sessionPayment}
              />
            </div>
        </div>
      </div>
    </div>
  );
}

export default SessionSetting;