import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import RecurrenceSessionGatewayInterface from "../../domain/Recurrence/RecurrenceSessionGatewayInterface";
import { FilterRecurrenceSessionInterface, RecurrenceSessionListInterface } from "../../domain/Recurrence/RecurrenceSessionList";
import { RecurrenceSessionInterface } from "../../domain/Recurrence/RecurrenceSession";
import { ActionList } from "../../domain/Recurrence/RecurrenceSessionAction";
import { ISettingForm } from "../../domain/Recurrence/setting";
import dayjs from 'dayjs'

export default class RecurrenceSessionGateway implements RecurrenceSessionGatewayInterface {
  getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterRecurrenceSessionInterface|null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{data: RecurrenceSessionListInterface[]|null, nb_element: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        product: filter?.product?.id || '',
        status: filter?.status || '',
        periodicity: filter?.periodicity || '',
        start: filter?.start || '',
        end: filter?.end || '',
        keyword: filter?.keyword || '',
      },
      signal
    ).
    then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        nb_element: 0
      }
    })
    .catch(() => {
      return {
        data: [],
        nb_element: 0
      }
    });
  }

  getExport(sessionId: string, type: string, filter: FilterRecurrenceSessionInterface|null): Promise<Blob|null> {
    return Caller.executeGetAsBlob(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/extract/${type}`, {
      'keyword': filter?.keyword || '',
    })
    .then(data => {
      if (data instanceof Blob) {
        return data
      }

      return null
    })
  }

  get(sessionId: string): Promise<RecurrenceSessionInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}`, {})
    .then(data => {
      if (data) {
        data.subscriptionAt = dayjs(data.subscriptionAt, 'DD/MM/YYYY').toDate()
        data.year = dayjs(data.year, 'YYYY').toDate()
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  create(session: ISettingForm, type: string): Promise<RecurrenceSessionInterface> {
    const params = {
      productId: session.sessionCriteria.product.id,
      label: session.session.label,
      type: type,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      periodicity: session.sessionCriteria.periodicity,
      subscription_at: dayjs(session.session.subscriptionAt).format('DD/MM/YYYY'),
      subscription_price: typeof session.sessionCriteria.share_price === 'string' ? parseFloat(String(session.sessionCriteria.share_price)) : session.sessionCriteria.share_price,
    }

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions`, params
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  update(session: ISettingForm, type: string, uuid: string): Promise<RecurrenceSessionInterface> {
    const params = {
      productId: session.sessionCriteria.product.id,
      label: session.session.label,
      type: type,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      periodicity: session.sessionCriteria.periodicity,
      subscription_at: dayjs(session.session.subscriptionAt).format('DD/MM/YYYY'),
      subscription_price: session.sessionCriteria.share_price,
    }

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${uuid}`, {...params}).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  getActions(uuid: string): Promise<ActionList | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${uuid}/actions/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
