import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

const Tab: FunctionComponent = () => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--partner`}>
      <NavLink
        to={`/${t('url.pre-subscription.dashboard')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('movement.tab.dashboard')}
      </NavLink>
      <NavLink
        to={`/${t('url.dismemberment-ledger.dashboard')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('dismemberment-ledger.tab.dashboard')}
      </NavLink>
    </div>
  )
}

export default Tab
