import { IFormInput } from './IFormInput';
import { PreSubscriptionInterface } from '../../../../../../domain/PreSubscription/PreSubscription';
import ProspectUtils from '../../../../util/ProspectUtils';

export const hydrateForm = (
  preSubscription: PreSubscriptionInterface,
  investors: any,
  prospectUtils: ProspectUtils
): IFormInput => {
  const formatInvestor = (investor: any): IFormInput['investor']['first'] => {
    if (!investor?.prospect?.persons) {
      return {
        id: null,
        identity: null,
        partnerId: '',
        partnerNumber: '',
        partnerSocialReason: '',
        platformNetworkNumber: null,
        platformNetworkLastName: null,
        partnerType: null,
      };
    }

    return {
      id: investor.prospect.prospectCode || null,
      identity: prospectUtils.formatProspectPersonIdentity(investor.prospect) || null,
      partnerId: '', // Ajoutez les valeurs par défaut ici si elles sont absentes
      partnerNumber: '',
      partnerSocialReason: '',
      platformNetworkNumber: null,
      platformNetworkLastName: null,
      partnerType: investor.partnerType || null,
    };
  };

  const formatMovementInvestor = (investor: any) => ({
    dismembermentKey: investor?.dismembermentKey || null,
    dismembermentAmount: investor?.amount || null,
    paymentMode: investor?.paymentMode || null,
    checkDepositAt: investor?.checkDepositAt || null,
    paymentStatus: investor?.paymentStatus || null,
    paymentNature: investor?.paymentNature || null,
    settlementDate: investor?.paymentDate || null,
  });

  const generalInformation = {
    product: {
      id: preSubscription.product?.id ?? null,
      label: preSubscription.product?.label ?? null,
      value: preSubscription.product?.id ?? null,
    },
    horodatage: preSubscription.horodatage,
    movementNumber: preSubscription.transactionCode,
    propertyType: preSubscription.propertyType,
    administrator: preSubscription.user
      ? `${preSubscription.user.lastname} ${preSubscription.user.firstname}`
      : '',
    possessionMode: preSubscription.possessionMode,
    subscribedAt: preSubscription.subscribedAt,
    updatedAt: preSubscription.updatedAt,
    lastUpdateUser: preSubscription.lastUpdateUser
      ? `${preSubscription.lastUpdateUser.lastname} ${preSubscription.lastUpdateUser.firstname}`
      : '',
  };

  const investor = {
    first: formatInvestor(investors.owner),
    second: formatInvestor(investors.usufructuary),
  };

  const particularity = {
    minor: preSubscription.minor,
    notFrench: preSubscription.notFrench,
    recurrent: preSubscription.recurrent,
    counterpartyResearch: preSubscription.counterpartyResearch,
    dateStartMandat: preSubscription.dateStartMandat,
    dateEndMandat: preSubscription.dateEndMandat,
    executionMandat: preSubscription.executionMandat,
    fundReceptionTimingMandat: preSubscription.fundReceptionTimingMandat,
    ppe: preSubscription.ppe,
    ter: preSubscription.ter,
    dividendReinvestment: preSubscription.dividendReinvestment,
    duration: preSubscription.duration,
    lifeAnnuity: preSubscription.lifeAnnuity,
    guardianship: preSubscription.guardianship,
    investment50k: preSubscription.investment50k,
  };

  const movement = {
    shareCount: preSubscription.shareCount,
    sharePrice: preSubscription.sharePrice,
    totalAmount: preSubscription.totalAmount,
    commissionStatus: preSubscription.commissionStatus,
    netAssetValueDate: preSubscription.netassetDate || null,
    totalNetAmount: preSubscription.totalNetAmount || null,
    commissionEarned: null,
    commissionNotEarned: null,
    investorFirst: formatMovementInvestor(investors.owner),
    investorSecond: formatMovementInvestor(investors.usufructuary),
  };

  const recurrence = {
    status: null,
    numRecurrence: null,
    period: {
      start: preSubscription?.recurrence?.period?.start || null,
      end: preSubscription?.recurrence?.period?.end || null,
    },
    periodicity: preSubscription?.recurrence?.periodicity || null,
    shareCount: preSubscription?.recurrence?.shareCount || null,
    sharePrice: preSubscription?.recurrence?.sharePrice || null,
    totalAmount: preSubscription?.recurrence?.totalAmount || null,
    rumCode: preSubscription?.recurrence?.rumCode || null,
    rumStatus: preSubscription?.recurrence?.rumStatus || null,
  };

  return {
    generalInformation,
    investor,
    particularity,
    movement,
    recurrence,
    note: '',
    comment: '',
    documents: {},
    bankInformations: {},
  };
};
