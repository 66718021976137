import { FilterDistributionSessionInterface } from "../../../domain/Distribution/SessionList"

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterDistributionSessionInterface|null
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterDistributionSessionInterface|null

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterDistributionSessionInterface|null,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
