type Filter = {
  keyword: string
  field: string
  type: string
}

export class Presenter {
  protected viewModel: any;
  protected viewModelListener: (_viewModel: any) => null;

  constructor(Params: { viewModel: any, viewModelListener?: any }) {
    this.viewModel = Params.viewModel;
    this.viewModelListener = Params.viewModelListener;
  }

  update(newValues: any) {
    this.viewModel = {...this.viewModel, ...newValues};
    this._refreshUI();
  }

  _refreshUI() {
    if(this.viewModelListener)
      this.viewModelListener(this.immutableViewModel());
  }

  immutableViewModel() {
    return {...this.viewModel};
  }
}

export type { Filter }
