import {FunctionComponent} from 'react'
import {toast, ToastOptions} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import imgValidation from '../../../assets/images/icons/validation.svg'
import imgAlert from '../../../assets/images/icons/alert.svg'

import '../../../assets/styles/components/_modal.scss'

interface Props {
    message?: string
    type?: 'success' | 'error' | 'warning'
    specificError?: string
}

const getImage = (type: string) => type === 'success'? imgValidation : imgAlert;

const Toast: FunctionComponent<Props> = ({message, type, specificError}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="content">
                {
                    type ? (
                        <div className="content__image">
                            <img src={getImage(type)} alt=""/>
                        </div>
                    ) : null
                }
                <p className={`content__text ${specificError ? "" : "u-pbb"}`}>
                {
                    message ? (message) : (
                        t('common.modal.validation-message')
                    )
                }
                </p>

                {specificError && <span className="content__specific-text">
                    {specificError}
                </span>}
            </div>
        </>
    );
}

export const toastOptions: ToastOptions = {
    position       : 'top-right',
    autoClose      : 5000,
    hideProgressBar: false,
    closeOnClick   : true,
    pauseOnHover   : true,
    draggable      : true,
    icon           : false,
}

export const toastSuccess = (message?: string) => {
    toast.success(<Toast message={message} type="success"/>, toastOptions);
}

export const toastSuccessWithLinks = (
  message?: string,
  links?: { label: string; to: string }[] // Chaque lien a un label et une destination
) => {
    toast.success(
      <div>
          <Toast message={message} type="success" />
          {links && links.length > 0 && (
            <ul style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
                {links.map(({ label, to }, index) => (
                  <li key={index}>
                      <a href={to}>{label}</a>
                  </li>
                ))}
            </ul>
          )}
      </div>,
      toastOptions
    )
}



export const toastProcess = (message?: string) => {
    return toast.success(<Toast message={message} type="success"/>, {
        position       : 'top-right',
        hideProgressBar: false,
        closeOnClick   : true,
        pauseOnHover   : true,
        draggable      : true,
        icon           : false,
        autoClose      : false,
        progress       : 100
    });
}

export const handleCloseToast = (toastId: any) => {
    if (toastId) {
        toast.dismiss(toastId);
    }
}

export const toastError = (message?: string|Blob, specificError?: string, altMessage?: string) => {
    if (message instanceof Blob) {
        const fr = new FileReader()
        fr.onload = function() {
          const jsonDecoded = JSON.parse(this.result?.toString() ?? '');
          const jsonError = jsonDecoded.error ? jsonDecoded.error : jsonDecoded.errors[0]?.messages ? jsonDecoded.errors[0]?.messages[0] : jsonDecoded.errors[0]?.error ? jsonDecoded.errors[0]?.error.toString() : null
          toast.error(<Toast message={jsonError ?? altMessage} specificError={specificError} type="error"/>, toastOptions);
        }
        fr.readAsText(message)
    } else {
        toast.error(<Toast message={message} specificError={specificError} type="error"/>, toastOptions);
    }
}

export const toastWarning = (message?: string) => {
    toast.warning(<Toast message={message} type="error"/>, toastOptions);
}
