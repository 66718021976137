import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/product'
import {setOpenDatalistFilterProduct} from '../../../store/component/event'
import {FilterProductInterface} from '../../../../domain/Product/ProductList'
import ProductListPresenter from '../../../../presenter/Product/ProductListPresenter'
import {ListRequest} from '../../../../useCase/Product/List/ListRequest'
import ListUseCase from '../../../../useCase/Product/List/ListUseCase'
import Pagination from '../Pagination/Pagination'
import ProductGateway from '../../../../gateway/Product/ProductGateway'
import HeaderRight from './Element/HeaderRight'
import download from '../../util/Download'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import MultiSelectCustom from "../Elements/MultiSelect";
import {
  ReferentielInterface
} from '../../../../domain/Referentiel/ReferentielInterface'
import TableHead from "../Table/TableHead";
import iconChevron from "../../../../assets/images/icons/chevron.svg";
import iconChevronSelected from "../../../../assets/images/icons/chevronSelected.svg";
import {CallerType} from "../../../../domain/Caller/Caller";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callerType: CallerType,
}

const ProductDatalist: FunctionComponent<Props> = ({callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterProduct = useAppSelector((state) => state.event.openDatalistFilterProduct)
  const productRules = useAppSelector((state) => state.me.me?.rules.product.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<string>(uuidV4());
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch, control } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterProduct.filters)
      const products = new ListUseCase(new ProductGateway()).execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder, callerType).then(response => {
        return response
      });
      const presenter = new ProductListPresenter(products);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('name', openDatalistFilterProduct.filters.name)
      setValue('keywords', openDatalistFilterProduct.filters.keywords)
    }

  }, [currentPage, openDatalistFilterProduct.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterProduct.filters])

  const onSubmit: SubmitHandler<FilterProductInterface> = data => {
    dispatch(setOpenDatalistFilterProduct({
      show: false,
      count: openDatalistFilterProduct.count,
      filters: {
        name: data.name,
        keywords: data.keywords,
        status: data.status
      }
    }))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterProduct({show: true, count: openDatalistFilterProduct.count, filters: openDatalistFilterProduct.filters}))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(uuidV4())
    dispatch(setOpenDatalistFilterProduct({
      show: false,
      count: 0,
      filters: {
        name: '',
        keywords: '',
        status: []
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new ProductGateway().getExport(openDatalistFilterProduct.filters).then(response => {
      if (response) {
        download(t('export.products'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input flex items-center">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
              <MultiSelectCustom
                  id="status"
                  name="status"
                  classes="u-mbs"
                  control={control}
                  label={t("filters.display-status")}
                  options={referential?.product.status || []}
                  customOnChange={ (options) => {setValue('status', options)}}
                  defaultValue={openDatalistFilterProduct.filters.status}
                  clearValues={clearMultiSelectValues}
              />
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
            </div>
          </form>
          <HeaderRight numberOfActivatedFilters={openDatalistFilterProduct.count}
                       handleClickFilter={handleClickFilter}
                       handleClickExport={handleClickExport}
                       isLoadingExport={isLoadingExport}
                       allowExport={productRules?.export}
                       hideFilter
          />
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
      <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          {viewModel.heading && <TableHead typeFilter={"API"}
                                           heading={viewModel.heading}
                                           sortOrder={sortOrder}
                                           setSortOrder={setSortOrder}
                                           viewModel={viewModel}
                                           setViewModel={setViewModel}
                                           filter={openDatalistFilterProduct.filters}
                                           watchNumberRows={watchNumberRows}
                                           currentPage={currentPage}
                                           listRequest={ListRequest}
                                           listUseCase={ListUseCase}
                                           listPresenter={ProductListPresenter}
                                           gateway={ProductGateway}
          />}
          </thead>
          <tbody>
          {viewModel.data.map((item: Array<string>) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                  <td>
                    {productRules?.read && <Link to={`/${t('url.product.read')}/${Object.entries(item)[0][1]}`}
                                                 target="_blank"
                                                 rel="noopener noreferrer"
                    >
                      <button type="button" className="button-reset">
                        <img src={iconSearch} alt=""/>
                      </button>
                    </Link>}
                    {productRules?.update && <Link to={`/${t('url.product.edit')}/${Object.entries(item)[0][1]}`}>
                      <button type="button" className="button-reset">
                        <img src={iconEdit} alt=""/>
                      </button>
                    </Link>}
                  </td>
                  {Object.entries(item).map((obj) => {
                    if (obj[0] !== 'id' && obj[0] !== 'subproduct') {
                      return <td key={uuidV4()}>{obj[1]}</td>
                    }
                  })}
                </>
              }
            </tr>
          ))}
          {viewModel.data.length === 0 &&
            <tr>
              <td colSpan={ viewModel?.heading.length }>{ t('common.data-is-empty') }</td>
            </tr>
          }
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default ProductDatalist
