import {Presenter} from '../Presenter'
import { DistributionSessionListInterface } from '../../domain/Distribution/SessionList'
import dayjs from 'dayjs'

class SessionListPresenter extends Presenter {

  private useCase: {
    retrieveSessionList: { data: DistributionSessionListInterface[] | null, nb_element: number }
  }

  constructor(retrieveSessionList: any) {
    super({
      viewModel: {
        title: 'distribution.session.search',
        heading: [
          {name: 'distribution.session.table.heading.action'},
          {name: 'distribution.session.table.heading.products'},
          {name: 'distribution.session.table.heading.session-number'},
          {name: 'distribution.session.table.heading.type'},
          {name: 'distribution.session.table.heading.status'},
          {name: 'distribution.session.table.heading.calculeAt'},
          {name: 'distribution.session.table.heading.validateAt'},
          {name: 'distribution.session.table.heading.paymentAt'},
          {name: 'distribution.session.table.heading.sendAt'},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    })

    this.useCase = {
      retrieveSessionList,
    }
  }

  async load() {
    try {
      this._setSessions(await this.useCase.retrieveSessionList)
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSessions(distributions: { data: DistributionSessionListInterface[] | null, nb_element: number }) {
    this.update({
      data: distributions.data?.map((session: DistributionSessionListInterface) => {
        return {
          'id': session.id,
          'month': session.month,
          'year': session.year,
          'status': session.status,
          'type': session.type,
          'products': session.products,
          'label': session.label,
          'createdAt': dayjs(session.createdAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          'updatedAt': (session.updatedAt) ? dayjs(session.updatedAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
          'calculeAt': (session.calculeAt) ? dayjs(session.calculeAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
          'validateAt': (session.validateAt) ? dayjs(session.validateAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
          'paidAt': (session.paidAt) ? dayjs(session.paidAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
          'sendAt': (session.sendAt) ? dayjs(session.sendAt.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
        }
      }),
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': distributions.nb_element,
      }
    })
  }
}

export default SessionListPresenter
