import { MatchRequestInterface } from './MatchRequest'
import DismembermentLedgerGateway from '../../../gateway/DismembermentLedger/DismembermentLedgerGateway'

export default class MatchUseCase {
  public dismembermentLedgerGateway

  constructor(DismembermentLedgerGateway: DismembermentLedgerGateway) {
    this.dismembermentLedgerGateway = DismembermentLedgerGateway
  }

  async execute(MatchRequest: MatchRequestInterface): Promise<string[] | null> {
    return await this.dismembermentLedgerGateway.match(
      MatchRequest.bareOwners,
      MatchRequest.usufructuaries,
    )
      .then(response => response)
  }
}
