import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'
import {Link} from "react-router-dom"

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setOpenDatalistFilterCustomer} from '../../../store/component/event'
import CustomerListPresenter from '../../../../presenter/Customer/CustomerListPresenter'
import HeaderRight from './Element/HeaderRight'
import download from '../../util/Download'
import {
  CustomerListInterface,
  FilterCustomerInterface
} from '../../../../domain/Customer/CustomerList'
import CustomerGateway from '../../../../gateway/Customer/CustomerGateway'
import Pagination from '../Pagination/Pagination'
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import iconChevron from '../../../../assets/images/icons/chevron.svg'
import iconChevronSelected from '../../../../assets/images/icons/chevronSelected.svg'

import '../../../../assets/styles/components/_datalist.scss'
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import MultiSelectCustom from "../Elements/MultiSelect";
import {TYPE_MORAL} from "../../../../domain/Prospect/Prospect";
import {
  ReferentielInterface
} from '../../../../domain/Referentiel/ReferentielInterface'
import {CallerType} from "../../../../domain/Caller/Caller";
import StatusChips from "../Status/StatusChips";
import ReactTooltip from "react-tooltip";
import CustomerListWithProductsPresenter from "../../../../presenter/Customer/CustomerListWithProductsPresenter";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callerType: CallerType
}

const CustomerDatalist: FunctionComponent<Props> = ({callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterCustomer = useAppSelector((state) => state.event.openDatalistFilterCustomer)
  const customerRules = useAppSelector((state) => state.me.me?.rules.customer.actions)

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [customers, setCustomers] = useState<{data: CustomerListInterface[]|null, numberOfItems: number}|null>(null)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<string>(uuidV4());
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch, control } = useForm()

  const watchNumberRows = watch('numberRows')


  useEffect(() => {
    const presenter = new CustomerListWithProductsPresenter(customers);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
    })
  }, [customers])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      new CustomerGateway().getList(
        callerType,
        currentPage,
        watchNumberRows || 50,
        openDatalistFilterCustomer.filters,
        controller.signal,
        sortOrder.sortLabel ?? '',
        sortOrder.sortOrder
      ).then(response => {
        setCustomers(response)
      })
      setValue('lastName', openDatalistFilterCustomer.filters.lastName)
      setValue('maidenName', openDatalistFilterCustomer.filters.maidenName)
      setValue('keywords', openDatalistFilterCustomer.filters.keywords)
      setValue('birthDate', openDatalistFilterCustomer.filters.birthDate)
    }

  }, [currentPage, openDatalistFilterCustomer.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterCustomer.filters])

  const onSubmit: SubmitHandler<FilterCustomerInterface> = data => {
    dispatch(setOpenDatalistFilterCustomer({
        show: false,
        count: openDatalistFilterCustomer.count,
        filters: {
          lastName: data.lastName,
          maidenName: data.maidenName,
          keywords: data.keywords,
          birthDate: data.birthDate,
          status: data.status
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterCustomer({show: true, count: openDatalistFilterCustomer.count, filters: openDatalistFilterCustomer.filters}))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(uuidV4())
    dispatch(setOpenDatalistFilterCustomer({
        show: false,
        count: 0,
        filters: {
          lastName: '',
          maidenName: '',
          keywords: '',
          birthDate: '',
          status: []
        }
      }
    ))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new CustomerGateway().getExport(openDatalistFilterCustomer.filters).then(response => {
      if (response) {
        download(t('export.customers'), response)
        setLoadingExport(false)
      }
    })
  }

  function defineSortOrder(sort: string|undefined): SortOrder {
    if (sortOrder.sortLabel !== sort) return SortOrder.ASC
    return sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
  }

  function sortData(sort: string|undefined) {
    if(sort) {
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineSortOrder(sort)
      };
      setSortOrder(order);
      new CustomerGateway().getList(
          callerType,
          currentPage,
          watchNumberRows || 50,
          openDatalistFilterCustomer.filters,
          controller.signal,
          sort,
          order.sortOrder).then(response => {
        setCustomers(response)
      })
    }
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input flex items-center">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
              <MultiSelectCustom
                  id="status"
                  name="status"
                  classes="u-mbs"
                  control={control}
                  label={t("filters.display-status")}
                  options={referential?.prospect.status || []}
                  customOnChange={ (options) => {setValue('status', options)}}
                  defaultValue={openDatalistFilterCustomer.filters.status}
                  clearValues={clearMultiSelectValues}
              />
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
            </div>
          </form>
          <HeaderRight numberOfActivatedFilters={openDatalistFilterCustomer.count}
                       handleClickFilter={handleClickFilter}
                       handleClickExport={handleClickExport}
                       isLoadingExport={isLoadingExport}
                       allowExport={customerRules?.export}
                       hideFilter
          />
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
        <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          <tr>
            {viewModel.heading.map((title: { name: string, sort?: string }) => (
              <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>
                {t(title.name)}
                {title.sort && sortOrder.sortLabel !== title.sort &&
                  <>
                    <img src={iconChevron} className="chevron-top" alt="chevron-top" />
                    <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom" />
                  </>
                }
                {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
                  <>
                    <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom" />
                  </>
                }
                {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
                  <>
                    <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top" />
                  </>
                }
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {viewModel.data !== undefined &&
            viewModel.data.map((item: CustomerListInterface) => (
            <tr key={uuidV4()}>
              <td>
                {customerRules?.read && <Link to={`/${t('url.customer.read-general-information')}/${item.id}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                >
                    <button type="button" className="button-reset">
                        <img src={iconSearch} alt="" />
                    </button>
                </Link>}
                {customerRules?.update && <Link to={`/${t('url.customer.edit-general-information')}/${item.id}`}>
                    <button type="button" className="button-reset">
                        <img src={iconEdit} alt=""/>
                    </button>
                </Link>}
              </td>
              <td>{item.accountId}</td>
              <td>{item.lastName}</td>
              <td>{item.maidenName === TYPE_MORAL ? '' : item.maidenName}</td>
              <td>{item.firstName}</td>
              <td>{item.socialReason}</td>
              <td>
                <div className="u-mys">
                  {
                    item.products && item.products.map((productName, index) => {
                      if (productName && index < 3) {
                        return <div
                          className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}
                          key={uuidV4()}>
                          <div className="badge__container">
                            <div className="badge__text">{productName}</div>
                          </div>
                        </div>
                      }
                  })}
                  {
                    item.products && item.products.length > 3 ? (
                      <div className={`badge badge--min badge--without-cta badge--partner-product-more u-mrs u-mbs`}>
                        <div className="badge__container">
                          <ReactTooltip id="more" />
                          <a className="badge__text"
                             data-tip=""
                             data-for={`more-${item.id}`}
                          >{`+${item.products.length -3}`}
                          </a>
                          <ReactTooltip id={`more-${item.id}`} place="right" effect="solid">
                            {
                              item.products.map((productName, index) => {
                                if (index>2) {
                                  return <span key={index}>{productName} <br /></span>
                                }
                              })
                            }
                          </ReactTooltip>
                        </div>
                      </div>) : null
                  }
                </div>
              </td>
              <td>{item.city}</td>
              <td>
                { referential && <StatusChips referential={referential} status={item.status} />}
              </td>
            </tr>
          ))}
          {viewModel.data === undefined || viewModel.data.length === 0 &&
            <tr>
              <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
            </tr>
          }
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default CustomerDatalist
