import { DismembermentLedgerGatewayInterface, DismembermentLedgerType } from '../../domain/DismembermentLedger/DismembermentLedgerGatewayInterface'
import { DismembermentLedgerListInterface, FilterDismembermentLedgerInterface } from '../../domain/DismembermentLedger/DismembermentLedgerList'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class DismembermentLedgerGateway implements DismembermentLedgerGatewayInterface {
  getList(type: DismembermentLedgerType,
          currentPage: number,
          itemsPerPage: number,
          filter: FilterDismembermentLedgerInterface|null,
          signal?: AbortSignal,
          sort?: string,
          order?: string ): Promise<{data: DismembermentLedgerListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/${type}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        product_acronyme: filter?.product.label || '',
        keywords: filter?.keywords || '',
        executions: filter?.executions ?? [],
        typeDurations: filter?.typeDurations ?? [],
        preferences: filter?.preferences ?? [],
      },
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
      .catch(() => {
        return {
          data: [],
          numberOfItems: 0
        }
      });
  }

  match(bareOwners: string[], usufructuaries: string[]): Promise<string[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/match`, {
        "bare-owners": bareOwners,
        "usufructuaries": usufructuaries
      },
    ).then(data => {
      if (data) {
        return data
      }

      return []
    })
      .catch(() => {
        return []
      });
  }
}
