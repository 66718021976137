import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../store/hook";
import DismembermentGateway from "../../../../gateway/Movement/Dismemberment/DismembermentGateway";
import {ListRequest} from "../../../../useCase/Dismenberment/List/ListRequest";
import ListUseCase from "../../../../useCase/Dismenberment/List/ListUseCase";
import {SubmitHandler, useForm} from "react-hook-form";
import {
  FilterDismenbermentInterface
} from "../../../../domain/Movement/Dismemberment/DismenbermentList";
import {setOpenDatalistFilterDismenberment} from "../../../store/component/event";
import DismenbermentListPresenter from "../../../../presenter/Movement/DismenbermentListPresenter";
import {v4 as uuidV4} from "uuid";
import iconHeadingSearch from "../../../../assets/images/icons/datalist-heading-search.svg";
import HeaderRight from "./Element/HeaderRight";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import Pagination from "../Pagination/Pagination";
import download from "../../util/Download";
import {Link} from "react-router-dom";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {MovementListInterface} from "../../../../domain/Movement/MovementList";
import MovementUtil from '../../util/MovementUtil';
import ProductDalalist from "./Product/ProductDalalist";
import MultiSelectCustom from "../Elements/MultiSelect";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import TableHead from "../Table/TableHead";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";


interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const DismenbermentDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterDismenberment = useAppSelector((state) => state.event.openDatalistFilterDismenberment)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)

  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<string>(uuidV4());
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { control, register, handleSubmit, setValue, watch, getValues } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(setOpenDatalistFilterDismenberment( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterDismenberment.filters)
      const dismenberments = new ListUseCase(new DismembermentGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      })

      const presenter = new DismenbermentListPresenter(dismenberments);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
      })
      setValue('product', openDatalistFilterDismenberment.filters.product)
      setValue('transactionType', openDatalistFilterDismenberment.filters.transactionType)
      setValue('status', openDatalistFilterDismenberment.filters.status)
      setValue('tags', openDatalistFilterDismenberment.filters.tags)
      setValue('propertyType', openDatalistFilterDismenberment.filters.propertyType)
      setValue('paymentMode', openDatalistFilterDismenberment.filters.paymentMode)
      setValue('user', openDatalistFilterDismenberment.filters.user)
    }

  }, [currentPage, openDatalistFilterDismenberment.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterDismenberment.filters])

  const onSubmit: SubmitHandler<FilterDismenbermentInterface> = data => {
    dispatch(setOpenDatalistFilterDismenberment({
        show: false,
        count: openDatalistFilterDismenberment.count,
        filters: {
          product: data.product,
          transactionType: data.transactionType,
          status: data.status,
          tags: data.tags,
          propertyType: data.propertyType,
          paymentMode: data.paymentMode,
          user: data.user,
          name: data.name,
          keywords: data.keywords
        }
      }
    ))
  }
  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterDismenberment({show: true, count: openDatalistFilterDismenberment.count, filters: openDatalistFilterDismenberment.filters}))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(uuidV4())
    dispatch(setOpenDatalistFilterDismenberment({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          transactionType: '',
          status: [],
          tags: [],
          propertyType: [],
          paymentMode: '',
          user: null,
          name: '',
          keywords: ''
        }
      }
    ))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new DismembermentGateway().getExport(openDatalistFilterDismenberment.filters).then(response => {
      if (response) {
        download(t('export.dismenberment'), response)
        setLoadingExport(false)
      }
    })
  }

  return (
    <>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header">
              <form onSubmit={handleSubmit(onSubmit)} className="filter">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                  <MultiSelectCustom
                    id="status"
                    name="status"
                    classes="u-mbs"
                    control={control}
                    label={t("filters.display-status")}
                    options={referential?.global.transaction_status || []}
                    customOnChange={ (options) => {setValue('status', options)}}
                    defaultValue={openDatalistFilterDismenberment.filters.status}
                    clearValues={clearMultiSelectValues}
                  />
                  <ProductDalalist classes=""
                                   id="product"
                                   name="product"
                                   control={control}
                                   defaultValue={getValues('product')}
                                   customPlaceHolder={t('common.search-by', {keyword:t('filters.by-product')})}
                                   maxContentFit
                  />
                </div>
                <div className="filter__actions">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                </div>
              </form>
              <HeaderRight numberOfActivatedFilters={openDatalistFilterDismenberment.count} handleClickFilter={handleClickFilter} handleClickExport={handleClickExport} isLoadingExport={isLoadingExport}  />
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="table-fix-head">
            <table className="datalist__datas">
              <thead>
              {viewModel.heading && <TableHead typeFilter={"API"}
                                               heading={viewModel.heading}
                                               sortOrder={sortOrder}
                                               setSortOrder={setSortOrder}
                                               viewModel={viewModel}
                                               setViewModel={setViewModel}
                                               filter={openDatalistFilterDismenberment.filters}
                                               watchNumberRows={watchNumberRows}
                                               currentPage={currentPage}
                                               listRequest={ListRequest}
                                               listUseCase={ListUseCase}
                                               listPresenter={DismenbermentListPresenter}
                                               gateway={DismembermentGateway}
              />}
              </thead>
              <tbody>
              {viewModel?.data?.map((item: MovementListInterface) => (
                <tr key={uuidV4()}>
                  {item !== null && item !== undefined &&
                  <>
                    <td>
                      <Link to={`/${t('url.movements.edit-dismemberment')}/${item.id}`}>
                        <button type="button" className="button-reset">
                          <img src={iconSearch} alt="" />
                        </button>
                      </Link>
                    </td>
                    <td>{item.product_label}</td>
                    <td>{item.transaction_type}</td>
                    <td>{item.code}</td>
                    <td>{item.engagedAt}</td>
                    <td>{item.status_label}</td>
                    <td>{item.property_type}</td>
                    <td>{item.investor_id}</td>
                    <td>{item.investor_name}</td>
                    <td>{item.partner_code}</td>
                    <td>{item.partner_name}</td>
                    <td>{item.user?.firstname?.toUpperCase().slice(0, 1)}. {item.user?.lastname?.toUpperCase()}</td>
                    <td>{item.payment_method}</td>
                    <td>{item.payment_status}</td>
                    <td>{item.payment_date}</td>
                    <td>{item.share_count}</td>
                    <td>{item.total_amount}</td>
                    <td>
                      <div className="u-mys">
                        {item.tags && item.tags?.map(tag => {
                          const key = `movement.tags.${tag}`
                          const type = MovementUtil.getType(tag)
                          return <div className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`} key={uuidV4()}>
                            <div className="badge__container">
                              <div className="badge__text">{t(key)}</div>
                            </div>
                          </div>
                        })}
                      </div>
                    </td>
                  </>

                  }
                </tr>
              ))}
              {viewModel?.data?.length === 0 &&
                <tr>
                  <td colSpan={7}>{t('common.data-is-empty')}</td>
                </tr>
              }
              </tbody>
            </table>
            </div>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default DismenbermentDatalist
