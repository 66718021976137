import { Investor } from './Investor'
import { Recurrence } from './Recurrence'
import { Product } from '../Product/Product'
import { User } from '../User/User'

interface PreSubscriptionInterface {
  id: string | null
  createdAt: string | null
  updatedAt: string | null
  subscribedAt: string | null
  deletedAt: string | null
  engagedAt: string | null
  canceledAt: string | null
  verifiedLevel1At: string | null
  verifiedLevel2At: string | null
  confirmedAt: string | null
  validationScheduledAt: string | null
  executedAt: string | null
  minor: boolean
  guardianship: boolean
  notFrench: boolean
  ppe: boolean
  ter: boolean
  recurrent: boolean
  dividendReinvestment: boolean
  counterpartyResearch: boolean
  dateStartMandat: string | null
  dateEndMandat: string | null
  executionMandat: string|null
  fundReceptionTimingMandat: string|null
  duration: number | null
  lifeAnnuity: boolean
  shareCount: number | null
  sharePrice: number | null
  totalAmount: number | null
  commissionStatus: string | null
  status: string | null
  propertyType: string | null
  possessionMode: string | null
  subscriptionCode: string | null
  investors: Investor[] | null
  recurrence: Recurrence | null
  product: Product | null
  tags: []
  investment50k: boolean
  status_label: string | null
  horodatage: string | null
  transactionCode: string | null
  user: User | null
  lastUpdateUser: User | null
  actionReason: string | null
  comment: string | null
  note: string | null
  netassetDate: string | null
  totalNetAmount: number | null
  source: string
}

class PreSubscription implements PreSubscriptionInterface {
  constructor(
    public id: string | null = null,
    public createdAt: string | null = null,
    public updatedAt: string | null = null,
    public subscribedAt: string | null = null,
    public deletedAt: string | null = null,
    public engagedAt: string | null = null,
    public canceledAt: string | null = null,
    public verifiedLevel1At: string | null = null,
    public verifiedLevel2At: string | null = null,
    public confirmedAt: string | null = null,
    public validationScheduledAt: string | null = null,
    public executedAt: string | null = null,
    public minor: boolean = false,
    public guardianship: boolean = false,
    public notFrench: boolean = false,
    public ppe: boolean = false,
    public ter: boolean = false,
    public recurrent: boolean = false,
    public dividendReinvestment: boolean = false,
    public counterpartyResearch: boolean = false,
    public dateStartMandat: string | null = null,
    public dateEndMandat: string | null = null,
    public executionMandat: string|null = null,
    public fundReceptionTimingMandat: string|null = null,
    public duration: number | null = null,
    public lifeAnnuity: boolean = false,
    public shareCount: number | null = null,
    public sharePrice: number | null = null,
    public totalAmount: number | null = null,
    public commissionStatus: string | null = null,
    public status: string | null = null,
    public propertyType: string | null = null,
    public possessionMode: string | null = null,
    public subscriptionCode: string | null = null,
    public investors: Investor[] | null = [],
    public recurrence: Recurrence | null = null,
    public product: Product | null = null,
    public tags: [] = [],
    public investment50k: boolean = false,
    public status_label: string | null = null,
    public horodatage: string | null = null,
    public transactionCode: string | null = null,
    public user: User | null = null,
    public lastUpdateUser: User | null = null,
    public actionReason: string | null = null,
    public comment: string | null = null,
    public note: string | null = null,
    public netassetDate: string | null = null,
    public totalNetAmount: number | null = null,
    public source: string = 'manual'
  ) {}
}


export { PreSubscription }
export type { PreSubscriptionInterface }
