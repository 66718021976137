import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ReferentielInterface } from '../../../../domain/Referentiel/ReferentielInterface';
import { saveNbRowsInLocalStorage } from '../../util/SavePreferencesInLocalStorage';
import { ListRequest } from '../../../../useCase/Movement/List/ListRequest';
import MovementGateway from '../../../../gateway/Movement/MovementGateway';
import { setCountTotal } from '../../../store/component/movement';
import { TransactionListInterface } from '../../../../domain/Movement/TransactionList';
import TransactionListUseCase from '../../../../useCase/Movement/List/TransactionListUseCase';
import TransactionListPresenter from '../../../../presenter/Movement/TransactionListPresenter';
import { v4 as uuidV4 } from 'uuid';
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg';
import HeaderRight from './Element/HeaderRight';
import SelectCustom from '../Elements/Select';
import { optionsNbRows } from '../../../../fixtures/Referentiel';
import { Link } from 'react-router-dom';
import iconSearch from '../../../../assets/images/icons/datalist-search.svg';
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg';
import Pagination from '../Pagination/Pagination';
import download from '../../util/Download';
import { setOpenDatalistFilterMovement } from '../../../store/component/event';
import { FilterMovementInterface } from '../../../../domain/Movement/MovementList';
import ReactTooltip from "react-tooltip";
import { getLabelByValue } from '../../util/ReferentialI18n'

interface ViewModelInterface {
  title: string;
  heading: [];
  data: [];
  filtersShortcut: [];
  filters: [];
  pagination: PaginationInterface;
  count: number;
}

interface PaginationInterface {
  itemsPerPage: number;
  numberOfItems: number;
}

type Props = {
  investorId: string
  isLectureMode: boolean
}

const TRANSACTION_NOT_IN_STATUS: Readonly<string[]> = ['validated', 'subscribed'];

const TransactionDatalist: FunctionComponent<Props> = (
  {
    investorId,
    isLectureMode,
  },
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterMovement = useAppSelector((state) => state.event.openDatalistFilterMovement);
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false);
  const controller = new AbortController();
  const preferences = localStorage.getItem('preferences');

  const { register, handleSubmit, setValue, watch } = useForm();

  const watchNumberRows = watch('numberRows');

  useEffect(() => {
    if (!watchNumberRows) {
      setValue('numberRows', preferences ? JSON.parse(preferences).numberRows : 50);
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows);
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterMovement.filters);
      const transactionsToCome = new TransactionListUseCase(new MovementGateway()).execute(listRequest, controller.signal).then(response => {
        return response;
      });

      const presenter = new TransactionListPresenter(transactionsToCome);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel());
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems));
      });

      setValue('product', openDatalistFilterMovement.filters.product);
      setValue('transactionType', openDatalistFilterMovement.filters.transactionType);
      setValue('status', openDatalistFilterMovement.filters.status);
      setValue('tags', openDatalistFilterMovement.filters.tags);
      setValue('propertyType', openDatalistFilterMovement.filters.propertyType);
      setValue('paymentMode', openDatalistFilterMovement.filters.paymentMode);
      setValue('user', openDatalistFilterMovement.filters.user);
      setValue('source', openDatalistFilterMovement.filters.source);
      setValue('investor_id', investorId);
    }

  }, [currentPage, openDatalistFilterMovement.filters, watchNumberRows]);

  useEffect(() => {
    setCurrentPage(1);
  }, [openDatalistFilterMovement.filters]);

  const onSubmit: SubmitHandler<FilterMovementInterface> = data => {
    dispatch(setOpenDatalistFilterMovement({
        show: false,
        count: openDatalistFilterMovement.count,
        filters: {
          product: data.product,
          transactionType: data.transactionType,
          status: data.status,
          tags: data.tags,
          propertyType: data.propertyType,
          paymentMode: data.paymentMode,
          user: data.user,
          investor_id: investorId,
          name: data.name,
          keywords: data.keywords,
          source: data.source,
        },
      },
    ));
  };
  const paginate = (pageNumber: number) => {
    controller.abort();
    setCurrentPage(pageNumber);
  };

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterMovement({
        show: true,
        count: openDatalistFilterMovement.count,
        filters: openDatalistFilterMovement.filters,
      }));
    }
  };
  const resetFilters = () => {
    dispatch(setOpenDatalistFilterMovement({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          transactionType: '',
          status: [],
          tags: [],
          propertyType: [],
          paymentMode: '',
          user: null,
          investor_id: investorId,
          name: '',
          keywords: '',
          source: '',
        },
      },
    ));
  };
  const getUrlMovement = (movement: any, mode: string) => {
    let url = '';

    switch (movement.transaction_type) {
      case 'withdrawal':
        url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-redemption-withdrawal`);
        break;
      case 'agreement':
        url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-gre-gre`);
        break;
      case 'mutation':
        url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-mutations`);
        break;
      case 'subscription':
        url = t(`url.pre-subscription.${ mode === 'read' ? 'read' : 'edit' }`);
        break;
      case 'dismemberment':
        url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-dismemberment`);
        break;
      case 'foo5':
        url = t('url.movements.edit-land-consolidation');
        break;
    }

    /* if (movement.transaction_type === 'subscription' && movement.property_type === 'dismemberment') {
      url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-dismemberment`);
    } */

    return `/${ url }/${ movement.id }`;
  };
  const handleClickExport = () => {
    setLoadingExport(true);
    new MovementGateway().getExport({
      product: openDatalistFilterMovement.filters.product,
      transactionType: openDatalistFilterMovement.filters.transactionType,
      status: openDatalistFilterMovement.filters.status,
      tags: openDatalistFilterMovement.filters.tags,
      propertyType: openDatalistFilterMovement.filters.propertyType,
      paymentMode: openDatalistFilterMovement.filters.paymentMode,
      user: openDatalistFilterMovement.filters.user,
      investor_id: openDatalistFilterMovement.filters.investor_id,
      name: openDatalistFilterMovement.filters.name,
      keywords: openDatalistFilterMovement.filters.keywords,
      source: openDatalistFilterMovement.filters.source,
    }).then(response => {
      if (response) {
        download(t('export.movements'), response);
        setLoadingExport(false);
      }
    });
  };

  const displayStringShort = (string: string | undefined, nbChar: number) => {
    if (string && string.length > nbChar) {
      return (
        <>
          <ReactTooltip id={`more-${string}`} />
          <span data-tip={string} data-for={`more-${string}`}>{`${string.substring(0, nbChar)} ...`}</span>
        </>
      );
    }
    return (
      <span>{string}</span>
    );
  };

  return (
    <>
      {viewModel !== null && (
        <>
          <div className={`datalist`}>
            <h4>{t('account.edit.movement-to-come')}</h4>
            <div className='datalist__title'>{t(viewModel.title)}</div>
            <div className='datalist__header'>
              <form onSubmit={handleSubmit(onSubmit)} className='filter'>
                <div className='filter__input'>
                  {viewModel.filtersShortcut.map((filter: { keyword: string; field: string; type: string }) => (
                    <div key={uuidV4()} className='input-no-border'>
                      <img src={iconHeadingSearch} alt='' />
                      <input
                        {...register(filter.field)}
                        placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
                        className='u-mxs'
                      />
                    </div>
                  ))}
                </div>
                <div className='filter__actions'>
                  <button type='submit' className='button button--submit'>
                    {t('search.submit')}
                  </button>
                  <button type='button' className='button button--white' onClick={() => resetFilters()}>
                    {t('search.cancel')}
                  </button>
                </div>
              </form>
              <HeaderRight
                numberOfActivatedFilters={openDatalistFilterMovement.count}
                handleClickFilter={handleClickFilter}
                handleClickExport={handleClickExport}
                isLoadingExport={isLoadingExport}
              />
            </div>
            <SelectCustom
              classes='flex justify-end u-mbs'
              id='numberRows'
              name='numberRows'
              label={t('filters.display-results-by')}
              options={optionsNbRows}
              register={register}
              noChoiceOption
            />
            <div className='overflow-auto'>
              <table className='datalist__datas table__investissement-information'>
                <thead>
                  <tr>
                    {viewModel.heading.map((title: string) => (
                      <th key={uuidV4()}>{t(title)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {viewModel.data !== undefined &&
                    viewModel.data
                      .filter((it: TransactionListInterface) => !TRANSACTION_NOT_IN_STATUS.includes(it.status))
                      .map((item: TransactionListInterface) => (
                        <tr key={uuidV4()}>
                          <td>
                            <Link to={getUrlMovement(item, 'read')} target='_blank' rel='noopener noreferrer'>
                              <button type='button' className='button-reset'>
                                <img src={iconSearch} alt='' />
                              </button>
                            </Link>
                            {!isLectureMode && (
                              <Link to={getUrlMovement(item, 'edit')}>
                                <button type='button' className='button-reset'>
                                  <img src={iconEdit} alt='' />
                                </button>
                              </Link>
                            )}
                          </td>
                          <td>{item.engagedAt ?? t('common.n-a')}</td>
                          <td>{item.action_confirmed_at ?? t('common.n-a')}</td>
                          <td>{t('common.n-a')}</td>
                          <td>{displayStringShort(item.product_label, 15)}</td>
                          <td>{item.share_count}</td>
                          <td>{t('common.n-a')}</td>
                          <td>{t('common.n-a')}</td>
                          <td>{t('common.n-a')}</td>
                          <td>{item.share_price}</td>
                          <td>{item.total_amount}</td>
                          <td>
                            {displayStringShort(
                              referential?.wallet_subscription.property_type
                                .find(property => {
                                  return property.value === item.property_type
                                })
                                ?.label.toString(),
                              20
                            )}
                          </td>
                          <td>{item.partner_code}</td>
                          <td>{displayStringShort(item.partner_name, 20)}</td>
                          <td>
                            {referential?.wallet_subscription.transaction_type
                              .find(property => {
                                return property.value === item.transaction_type
                              })
                              ?.label.toString()}
                          </td>
                          <td>{item.dismembermentEndAt ?? ''}</td>
                          <td>{item.dismemberment_key ?? ''}</td>
                          <td>{item.status_label}</td>
                          <td>{getLabelByValue(item.source ?? '', referential?.transaction.source ?? [])}</td>
                        </tr>
                      ))}
                  {viewModel.data === undefined ||
                    (viewModel.data.filter(
                      (it: TransactionListInterface) => !TRANSACTION_NOT_IN_STATUS.includes(it.status)
                    ).length === 0 && (
                      <tr>
                        <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
            numberOfItems={viewModel.pagination.numberOfItems}
            callback={paginate}
          />
        </>
      )}
    </>
  )
};

export default TransactionDatalist;
