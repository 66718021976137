import {Presenter} from '../Presenter'
import {MovementListInterface} from '../../domain/Movement/MovementList'
import {InvestmentListInterface} from "../../domain/Movement/InvestmentList";

class InvestmentInformationPresenter extends Presenter {

  private useCase: {
    getInvestments: { data: InvestmentListInterface[] | null, nb_element: number }
  }

  constructor(getInvestments: any) {
    super({
      viewModel: {
        title: 'movement.search-a-movement',
        heading: [
          'movement.heading.action',
          'movement.investment.heading.date',
          'movement.investment.heading.action_confirmed_at',
          'movement.investment.heading.esn_type',
          'movement.investment.heading.product',
          'movement.investment.heading.share_count',
          'movement.investment.heading.share_start',
          'movement.investment.heading.share_end',
          'movement.investment.heading.possession_date',
          'movement.investment.heading.share_price',
          'movement.investment.heading.amount',
          'movement.investment.heading.property_type',
          'movement.investment.heading.partner_code',
          'movement.investment.heading.partner_name',
          'movement.investment.heading.movement_type',
          'movement.investment.heading.dismembermentEndAt',
          'movement.investment.heading.dismembermentKey',
          'movement.investment.heading.status',
          'movement.investment.heading.source',
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'name', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });
    this.useCase = {
      getInvestments,
    }
  }

  async load() {
    try {
      this._setInvestments(await this.useCase.getInvestments);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setInvestments(investments: { data: InvestmentListInterface[]|null, nb_element: number }) {
    this.update({
      data: investments.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': investments.nb_element,
      }
    });
  }
}

export default InvestmentInformationPresenter
