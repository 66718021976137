import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, useFormState} from 'react-hook-form'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenDatalistFilterDismembermentLedger} from '../../../../store/component/event'
import blockScrollBody from '../../../util/BlockScroll'
import {
  ReferentielInterface,
} from "../../../../../domain/Referentiel/ReferentielInterface";

import {usePrompt} from "../../../util/Navigation";
import {FilterDismembermentLedgerInterface} from "../../../../../domain/DismembermentLedger/DismembermentLedgerList"
import getClassForOverlay from "../../../util/Sidebar"
import ProductDalalist from "../../Datalist/Product/ProductDalalist"
import MultiSelectCustom from "../../Elements/MultiSelect"
import SelectCustom from "../../Elements/Select"

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

const DismembermentLedgerDatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterDismembermentLedger = useAppSelector(
    (state) => state.event.openDatalistFilterDismembermentLedger
  );

  const { control, handleSubmit, reset, setValue, getValues } = useForm<FilterDismembermentLedgerInterface>();

  useEffect(() => {
    blockScrollBody(openDatalistFilterDismembermentLedger.show);
  }, [openDatalistFilterDismembermentLedger]);

  useEffect(() => {
    setValue('product', openDatalistFilterDismembermentLedger.filters.product)
    setValue('keywords', openDatalistFilterDismembermentLedger.filters.keywords)
    setValue('executions', openDatalistFilterDismembermentLedger.filters.executions)
    setValue('typeDurations', openDatalistFilterDismembermentLedger.filters.typeDurations)
    setValue('preferences', openDatalistFilterDismembermentLedger.filters.preferences)
  }, [openDatalistFilterDismembermentLedger.filters, setValue]);

  const onConfirm = (data: FilterDismembermentLedgerInterface) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: openDatalistFilterDismembermentLedger.count,
        filters: data,
      })
    );
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          executions: [],
          typeDurations: [],
          preferences: [],
          keywords: ''
        }
      })
    )
  }

  return (
    <>
      {referential &&
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openDatalistFilterDismembermentLedger.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterDismembermentLedger.show ? "sidebar--active" : ""
            }`}
          >
            <form
              onSubmit={handleSubmit(onConfirm)}
              className="form-bloc form-bloc--partner-address-form"
            >
              <div className="sidebar__content">
                <div className="title">{t("common.filters")}</div>
                <div className="form-bloc__form flex-container">
                  <ProductDalalist classes="col-md-6"
                                   id="product"
                                   name="product"
                                   label={t('dismemberment-ledger.filter.product')}
                                   control={control}
                                   defaultValue={getValues('product')}
                  />
                  <MultiSelectCustom
                      id="executions"
                      name="executions"
                      classes="col-md-6"
                      control={control}
                      label={t("dismemberment-ledger.filter.executions")}
                      options={referential?.wallet_subscription.execution_mandat || []}
                      customOnChange={ (options) => {setValue('executions', options)}}
                  />
                  <MultiSelectCustom
                      id="typeDurations"
                      name="typeDurations"
                      classes="col-md-6"
                      control={control}
                      label={t("dismemberment-ledger.filter.typeDurations")}
                      options={referential?.wallet_subscription.execution_mandat || []}
                      customOnChange={ (options) => {setValue('typeDurations', options)}}
                  />
                  <MultiSelectCustom
                      id="preferences"
                      name="preferences"
                      classes="col-md-6"
                      control={control}
                      label={t("dismemberment-ledger.filter.preferences")}
                      options={referential?.wallet_subscription.execution_mandat || []}
                      customOnChange={ (options) => {setValue('preferences', options)}}
                  />
                  <footer className="sidebar__footer">
                      <button type="submit" className="button button--ink-2 u-mrm">
                        {t("common.save")}
                      </button>
                      <button
                          type="button"
                          className="button button--ink-2 button--ink-2--outline"
                          onClick={() => handleClose()}
                      >
                        {t("common.cancel")}
                      </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  );
}

export default DismembermentLedgerDatalistFilter
